import axiosInstance from "../../utility/axiosInstance";

// state
import { openNotification } from "../../modules";
import { setLoading, disableLoading } from "../../store/slices/loading";
import { disableServiceRequestLoading, setServiceRequestLoading, setServiceRequest } from "../../store/slices/serviceRequest";
 
export const getAllServiceRequest = async (dispatch, navigate) => {
    dispatch(setLoading());
    try {
        const response = await axiosInstance(navigate).get('/get-requests'); // show-file-logs
        if (response.data) {
            const { data } = response.data
            dispatch(setServiceRequest(data))
        }
    } catch (err) {
        const {data} = err.response;
        openNotification({type: 'error', title: 'Incorrect Credentials', description: data.message})
    }
    dispatch(disableLoading());
}

export const createServiceRequest = async (formData, dispatch, navigate) => {
    dispatch(setServiceRequestLoading());
    try {
        const response = await axiosInstance(navigate).post('/create-services', formData);
        if (response.data) {
            openNotification({type: 'success', title: 'Successful!', description: response.data.message})
        }        
        setTimeout(() => {
            window.location.reload()
        }, 500)
    } catch (err) {
        const {data} = err.response;
        openNotification({type: 'error', title: 'Incorrect Credentials', description: data.message})
    }
    dispatch(disableServiceRequestLoading());
}

export const editServiceRequest = async (data, dispatch, navigate) => {
    dispatch(setServiceRequestLoading());
    try {
        const response = await axiosInstance(navigate).put(`/edit-training`, data);
        if (response.data) {
            openNotification({type: 'success', title: 'Successful!', description: response.data.message})
        }
        setTimeout(() => {
          window.location.reload()
        }, 500)
    } catch (err) {
        const {data} = err.response;
        openNotification({type: 'error', title: 'Incorrect Credentials', description: data.message})
    }
    dispatch(disableServiceRequestLoading());
}