import React from "react";

const Button = ({ title, type, onClick }) => {
  return (
    <button
      type={type || "button"}
      onClick={onClick}
      className="w-full bg-[#67C748] border-none py-2 font-bold text-white text-center rounded-sm"
    >
      {title}
    </button>
  );
};

export default Button;
