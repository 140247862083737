import React from 'react'
// import useDashboard from '../hooks/useDashboard';
// state
import { useDispatch, useSelector } from 'react-redux';

// components
import { FaRegBuilding } from 'react-icons/fa';
import { Table, Tooltip, Button, Modal, Form, Input, Tag } from 'antd'

// constants and icon
import { ArrowLeftOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { createOpportunityType, setRemoveOppType } from '../../../services/apis/opportunity';
import { Link, useNavigate } from 'react-router-dom';
import useCreateOpportunity from '../hooks/useCreateOpportunity';

const { confirm } = Modal
const OpportunityType = () => {
    const opportunity = useSelector(({opportunity}) => opportunity)
    const { opportunityType } = opportunity
    const dispatch = useDispatch();
    const navigate = useNavigate();
    console.log(opportunity)
    const [visible, setVisible] = React.useState(false);
//    const editOpen = (data) =>{
//     setEdit(data)
//     console.log(data)
//     setViewEdit(true)
//   }
  
  const onFinish = data =>{
    // console.log(data)
    createOpportunityType({...data}, dispatch, navigate)
    // setVisible(false)
  }
  const onHandler =()=>{
    setVisible(false)
  }
    const loading = useSelector(({loading}) => loading.loading);
//   const {onLoad} = useDashboard();
  const {onLoad, onGetOpportunityType} = useCreateOpportunity();

  React.useEffect(() => {
    onLoad();
    onGetOpportunityType()
  }, [onLoad, onGetOpportunityType]);

  // const delOpport = id => {
  //   console.log(id)
  // }
  const delOpport = ({id, name }) => {
    const data = {
        id:''
    }
      confirm({
        title: `Are you sure you want to delete ${name} ?`,
        icon: <ExclamationCircleOutlined />,
        content: (<h4>{name}</h4>),
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk() {
          setRemoveOppType({...data, id }, dispatch, navigate)
        },
        onCancel() {
          console.log('Cancel')
        },
      })
    }
  

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: e => (
        <p className="bg-light position text-dark text-truncate" style={{ maxWidth: '150px' }}>
          <Tooltip placement="topLeft" title={e}>
            {e}
          </Tooltip>
        </p>
      )
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (e, data) => (
        <>{data?.status === '1' ? <Tag color='green'>Active</Tag> : <Tag color='red'>Inactive</Tag>}</>
      ),
    },
    {
      title: 'Action',
      key: 'id',
      dataIndex: 'id',
      render: (id, data) => (
        <>
          {/* <Tooltip title="Edit Opportunity">
            <Button className='bg-success text-white' shape="circle" icon={<EditOutlined />} onClick={() => editOpen(data)} />
          </Tooltip> */}
          <Tooltip title="Delete Opportunity">
            <Button className='bg-danger text-white' shape="circle" icon={<DeleteOutlined />} onClick={() => delOpport(data)} />
          </Tooltip>
        </>
      ),
    },
  ];

  return (
    <div style={{flex: 1}}>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <div>
          <Button type="primary" icon={<FaRegBuilding />} size='large' onClick={() => setVisible(true)}>
            Create
          </Button>
        </div>
        <div>
          <Link to='/opportunity'>
            <Button type="primary" icon={<ArrowLeftOutlined />} size='large'>
              Back
            </Button>
          </Link>
        </div>
      </div>
      <div style={{display: 'flex', flex: 1, flexDirection: 'column', marginTop: 20}} className="kit__utils__table">
        <h2>Opportunity Type</h2>
        <Table 
            columns={columns}
            rowKey="id"
            dataSource={opportunityType}
            loading={loading}
            className="mb-2"
            pagination={false} 
        />
      </div>
      
    <Modal
        title="Add Oppotunity"
        open={visible}
        footer={false}
        // confirmLoading={loading}
        onCancel={onHandler}
      >
        <Form layout="vertical" onFinish={onFinish} > 
            <Form.Item label='Opportunity Type' name='name' rules={[{ required: true }]}>
                <Input 
                    required 
                    placeholder="Enter Name of the Opportunity Type" 
                />
            </Form.Item>              
            <Form.Item>
                <Button
                htmlType="submit"
                loading={loading}
                size="large"
                >
                Create
                </Button>
            </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default OpportunityType
