import React from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getAllCompany } from '../../../services/apis/company';
import { getAllCountry, getState } from '../../../services/apis/country';

const useCreateUser = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [data, setData] = React.useState({
    "name": "",
    "address": "",
    "phone": "",
    "email": "",
    "accept_policy": "",
    "password": "",
    "password_confirmation": ""
  });

  const onChangeText = (text) => {
    const {value, name} = text.target;
    setData(prev => ({...prev, [name]: value}));
  }

  const onValidate = () => {
    let err;
    if (!data.name) {
      err = true;
    }
    if (!data.phone) {
      err = true;
    }
    if (!data.email) {
      err = true;
    }
    if (!data.address) {
      err = true;
    }
    if (err) {
      return false;
    }
    return true;
  };

  const getStateCountry = (id) => {
    getState(id, dispatch, navigate);
  }

  const onLoad = React.useCallback(() => {
    getAllCompany(dispatch, navigate);
  }, [dispatch, navigate])

  const onGetCountry = React.useCallback(() => {
    getAllCountry(dispatch, navigate)
  }, [dispatch, navigate]);

  return {data, setData, onLoad, onGetCountry, getStateCountry, onChangeText, onValidate}
}

export default useCreateUser
