/* eslint-disable camelcase */
import React from "react";

// react router
import { useLocation } from "react-router-dom";

// compoenents
import { Avatar, Button, Card, Spin, Tag } from "antd";
// import { Bounce } from 'react-reveal'

// state
import { useSelector } from "react-redux";
import PageHeading from "../../../component/heading";
import { LeftOutlined, UserOutlined } from "@ant-design/icons";
import DataDisplay from "../../../component/dataDisplay";
import FileLog from "../../../component/fileLog";
import SetAdminLog from "../../../component/checkCard";

// const text = `
//   A dog is a type of domesticated animal.
//   Known for its loyalty and faithfulness,
//   it can be found as a welcome guest in many households across the world.
// `;

const UserDetails = () => {
  const location = useLocation();
  const { state } = location;
  // const [viewMore, setViewMore] = React.useState(false)
  // const data = useSelector(({user}) => user)
  // const { user, fileLogs, docFile} = data
  const { email, role, profile } = state;
  const { company } = profile;
  const loading = useSelector(({ loading }) => loading.loading);
  const screenWidth = window.innerWidth;
  return (
    <Spin spinning={loading} tip="loading..." size="large">
      <div className="w-100">
        <div className="d-flex justify-content-between">
          <PageHeading
            title={`${profile.name} Details`}
            img="/settings.png"
            wClass="w-50"
          />
          {/* onClick={()=> history.goBack()} */}
          <Button type="text" icon={<LeftOutlined />}>
            Back
          </Button>
        </div>
        <div className="row">
          <div className="col-lg-8">
            <Card loading={loading} className="bg-transparent border-0">
              <div className="row">
                <div className="col-md-3 pe-0 bg-transparent text-center">
                  <Avatar
                    className="img-fluid mx-auto bg-transparent"
                    alt="zero user"
                    src={profile.avatar || `/avatar.png`}
                    shape="square"
                    size={180}
                    icon={<UserOutlined />}
                  />
                </div>
                <div className="col-md-9">
                  <ul className="list-group rounded-0">
                    <DataDisplay title="Name" detail={profile.name} />
                    <DataDisplay title="Email" detail={email} />
                    <DataDisplay title="Mobile Number" detail={profile.phone} />
                    <DataDisplay
                      title="Membership Type"
                      detail={
                        role === "0" ? (
                          <Tag>Member</Tag>
                        ) : (
                          <Tag>Company Admin</Tag>
                        )
                      }
                    />
                  </ul>
                </div>
                <div className="col-md-12">
                  <ul className="list-group rounded-0">
                    <DataDisplay title="Address" detail={profile.address} />
                    <DataDisplay
                      title="ID Number"
                      detail={profile.nin_number}
                    />
                    {/* <DataDisplay title="State Of Origin" detail={profile.sor} />
                    <DataDisplay title="Local Govt" detail={profile.lg} />
                    <DataDisplay title="Marital Status" detail={profile.marital} /> */}
                  </ul>
                </div>
                <div>
                  {/* <Bounce bottom> */}
                  <ul className="list-group rounded-0">
                    <b className="fs-5 mt-4">Company Informations</b>
                    <DataDisplay
                      title="Company Name"
                      detail={company && company.company_name}
                    />
                    <DataDisplay
                      title="Company Email"
                      detail={company && company.company_email}
                    />
                    <DataDisplay
                      title="Company Mobile Number"
                      detail={company && company.company_phone}
                    />
                    <DataDisplay
                      title="Company Website"
                      detail={company && company.company_website}
                    />
                    <DataDisplay
                      title="Company RC-Number"
                      detail={company && company.rc_number}
                    />
                    <DataDisplay
                      title="Company Premium Status"
                      detail={company && company.premium_status}
                    />
                    <DataDisplay
                      title="Company Status"
                      detail={company && company.status}
                    />
                  </ul>
                  {/* </Bounce> */}
                </div>
              </div>
            </Card>
          </div>

          <div className="col-lg-4">
            <p className="fs-5 fw-bold mt-4 mb-0">Settings Actions</p>
            <FileLog
              width={screenWidth <= 500 ? "100%" : "378"}
              loading={loading}
              profile={profile}
              company={company}
              data={company && company.documents}
            />
            {role === 0 && (
              <SetAdminLog
                width={screenWidth <= 500 ? "100%" : "378"}
                loading={loading}
                profile={profile}
                company={company}
                email={email}
              />
            )}
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default UserDetails;
