import axiosInstance from "../../utility/axiosInstance";

// state
import {setUser, setUserDocFile, setUserFileLog, setUsers} from '../../store/slices/user';
import { setLoading, disableLoading } from "../../store/slices/loading";
import { setUserLoading, disableUserLoading } from "../../store/slices/user";
import { openNotification } from "../../modules";

export const getUsers = async (dispatch, navigate, page=1) => {
    dispatch(setLoading());
    try {
        const response = await axiosInstance(navigate).get(`/get-users?page=${page}`);
        if (response.data) {
            dispatch(setUsers(response.data))
        }
    } catch (err) {
        const {data} = err?.response;
        openNotification({type: 'error', title: 'Incorrect Credentials', description: data.message})
    }
    dispatch(disableLoading());
}

export const getUserDetails = async (id, dispatch, navigate) => {
    dispatch(setLoading());
    try {
        const response = await axiosInstance(navigate).get(`/user-details/${id}`);
        if (response.data) {
            dispatch(setUser(response.data))
        }
    } catch (err) {
        const {data} = err.response;
        openNotification({type: 'error', title: 'Incorrect Credentials', description: data.message})
    }
    dispatch(disableLoading());
}
export const getUserFileLogs = async (id, dispatch, navigate) => {
    dispatch(setLoading());
    try {
        const response = await axiosInstance(navigate).get(`/user-file-logs/${id}`);
        if (response.data) {
            dispatch(setUserFileLog(response.data))
        }
    } catch (err) {
        const {data} = err.response;
        openNotification({type: 'error', title: 'Incorrect Credentials', description: data.message})
    }
    dispatch(disableLoading());
}
export const getUserDocFile = async (id, dispatch, navigate) => {
    dispatch(setLoading());
    try {
        const response = await axiosInstance(navigate).get(`/user-doc-shares/${id}`);
        if (response.data) {
            dispatch(setUserDocFile(response.data))
        }
    } catch (err) {
        const {data} = err.response;
        openNotification({type: 'error', title: 'Incorrect Credentials', description: data.message})
    }
    dispatch(disableLoading());
}
// export const getAllCompany = async (dispatch, navigate) => {
//     dispatch(setLoading());
//     try {
//         const response = await axiosInstance(navigate).get('/get-all-company');
//         if (response.data) {
//             dispatch(setCompany(response.data))
//         }
//     } catch (err) {
//         const {data} = err.response;
//         openNotification({type: 'error', title: 'Incorrect Credentials', description: data.message})
//     }
//     dispatch(disableLoading());
// }

export const CreateUsers = async (data, dispatch, navigate) => {
    console.log(data)
    dispatch(setUserLoading());
    try {
        const response = await axiosInstance(navigate).post('/create-user', data);
        if (response.data) {
            openNotification({type: 'success', title: 'Successful!', description: response.data.message})
        }        
        setTimeout(() => {
            window.location.reload()
        }, 500)
    } catch (err) {
        const {data} = err.response;
        openNotification({type: 'error', title: 'Incorrect Credentials', description: data.message})
    }
    dispatch(disableUserLoading());
}

export const AddUsers2Company = async (data, dispatch, navigate) => {
    console.log(data)
    dispatch(setUserLoading());
    try {
        const response = await axiosInstance(navigate).post('/add-to-company', data);
        if (response.data) {
            openNotification({type: 'success', title: 'Successful!', description: response.data.message})
        }        
        setTimeout(() => {
            window.location.reload()
        }, 500)
    } catch (err) {
        const {data} = err.response;
        openNotification({type: 'error', title: 'Incorrect Credentials', description: data.message})
    }
    dispatch(disableUserLoading());
}

export const SetAdminCompany = async (data, dispatch, navigate) => {
    dispatch(setUserLoading());
    try {
        const response = await axiosInstance(navigate).post('/make-user-admin', data);
        if (response.data) {
            openNotification({type: 'success', title: 'Successful!', description: response.data.message})
        }        
        setTimeout(() => {
            window.location.reload()
        }, 500)
    } catch (err) {
        const {data} = err.response;
        openNotification({type: 'error', title: 'Incorrect Credentials', description: data.message})
    }
    dispatch(disableUserLoading());
}

// export const getPaginatedCompany = async (dispatch, navigate, page = 1) => {
//     dispatch(setLoading());
//     try {
//         const response = await axiosInstance(navigate).get(`/get-company?page=${page}`);
//         if (response.data) {
//             dispatch(setCompanies(response.data))
//         }
//     } catch (err) {
//         const {data} = err.response;
//         openNotification({type: 'error', title: 'Incorrect Credentials', description: data.message})
//     }
//     dispatch(disableLoading());
// }

// export const searchCompany = async (search, dispatch, navigate) => {
//     dispatch(setLoading());
//     try {
//         const response = await axiosInstance(navigate).get(`/search-company/${search}`);
//         if (response.data) {
//             dispatch(setSearch(response.data))
//         }
//     } catch (err) {
//         const {data} = err.response;
//         openNotification({type: 'error', title: 'Incorrect Credentials', description: data.message})
//     }
//     dispatch(disableLoading());
// }