import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  trainings: [],
  search: [],
  loading: false,
  training: null,
  last_page: null,
  current_page: null,
  countries: []
}

export const training = createSlice({
  name: 'training',
  initialState,
  reducers: {
    setOpportunities: (state, action) => {
      state.trainings = action.payload.data;
      state.last_page = action.payload.last_page
      state.current_page = action.payload.current_page
  },
  setTraining: (state, action) => {
        state.trainings = action.payload;
    },
    setAllCountry: (state, action) => {
          state.countries = action.payload;
      },
    setSearch: (state, action) => {
        state.trainings = action.payload.data;
    },
    setTrainingLoading: (state) => {
        state.loading = true;
    },
    disableTrainingLoading: (state) => {
        state.loading = false;
    }
  },
})

// Action creators are generated for each case reducer function
export const { setSearch, setUpload, setAllCountry, setTraining, setTrainingLoading, disableTrainingLoading, setOpportunities } = training.actions

export default training.reducer