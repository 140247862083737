import React from 'react'
import Navbar from './Navbar'

import { Layout } from 'antd';

const { Content } = Layout;

const ContentComponent = ({children}) => {
  return (
    <Layout
        className="site-layout"
        style={{
          marginLeft: 200,
        }}
      >
        <Navbar />
        <Content style={{margin: '24px 16px 0', overflow: 'initial', minHeight: '100vh', paddingBottom: 20}}>
          <div className="site-layout-background"
            style={{ padding: 24, textAlign: 'center', display: 'flex', flex: 1,  height: '100%' }}>
            {children}
          </div>
        </Content>
    </Layout>
  )
}

export default ContentComponent
