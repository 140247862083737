import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { Theme } from '../../themes'

// pages
import DashBoard from '../dashboard/dashboard'
import {Company} from '../company'
import {Country, CountryDetails} from '../country'
import {User, UserDetails} from '../users';
import { Opportunity } from '../opportunity'
import { Training } from '../training'
import { CompaniesFile } from '../companiesFile'
import CompanyDetails from '../company/details'
import Services from '../services/dashboard'
import ServiceRequest from '../serviceRequest/dashboard'
import OpportunityType from '../opportunity/misc/OpportunityType'
import Blog from '../Blog/dashboard'
import BlogCats from '../Blog/misc/BlogCate'
import BlogTags from '../Blog/misc/BlogTags'

const Layout = () => {
  return (
    <Theme>
      <Routes>
        <Route path="/" exact element={<DashBoard />} />
        <Route path="/opportunity" exact element={<Opportunity />} />
        <Route path="/opportunity-type" exact element={<OpportunityType />} />
        <Route path="/training" exact element={<Training />} />
        <Route path="/blog" exact element={<Blog />} />
        <Route path="/blog-cat" exact element={<BlogCats />} />
        <Route path="/blog-tag" exact element={<BlogTags />} />
        <Route path="/users" exact element={<User />} />
        <Route path="/company" exact element={<Company />} />
        <Route path="/companies-files" exact element={<CompaniesFile />} />
        <Route path="/services" exact element={<Services />} />
        <Route path="/request" exact element={<ServiceRequest />} />
        <Route path="/countries" exact element={<Country />} />
        <Route path="/country/:id" exact element={<CountryDetails />} />
        <Route path="/user/details" exact element={<UserDetails />} />
        <Route path="/company/details/:id" exact element={<CompanyDetails />} />
      </Routes>
    </Theme>
  )
}

export default Layout
