import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  services: [],
  search: [],
  loading: false,
  service: null,
  last_page: null,
  current_page: null,
  upload:{}
}

export const service = createSlice({
  name: 'service',
  initialState,
  reducers: {
    setServices: (state, action) => {
      state.services = action.payload.data;
      state.last_page = action.payload.last_page
      state.current_page = action.payload.current_page
  },
  setService: (state, action) => {
        state.services = action.payload;
    },
    setUpload: (state, action) => {
          state.upload = action.payload;
      },
    setSearch: (state, action) => {
        state.services = action.payload.data;
    },
    setServiceLoading: (state) => {
        state.loading = true;
    },
    disableServiceLoading: (state) => {
        state.loading = false;
    }
  },
})

// Action creators are generated for each case reducer function
export const { setSearch, setUpload, setService, setServiceLoading, disableServiceLoading, setServices } = service.actions

export default service.reducer