import React from "react";
import useDashboard from "../hooks/useDashboard";
// state
import { useSelector } from "react-redux";

// components
import CreateRequest, { ViewModal, EditModal } from "../misc/CreateRequest";
import { FaRegBuilding } from "react-icons/fa";
import { Table, Tag, Tooltip, Button } from "antd";

// constants and icon
import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";

const ServiceRequest = () => {
  const serviceRequest = useSelector(({ serviceRequest }) => serviceRequest);
  const { serviceRequests } = serviceRequest;
  const loading = useSelector(({ loading }) => loading.loading);
  const {
    onLoad,
    editOpen,
    viewOpen,
    setEdit,
    edit,
    viewEdit,
    onCloseView,
    viewOne,
    viewOp,
    onCloseEdit,
    visible,
    setVisible,
    onCloseModal,
  } = useDashboard();
  React.useEffect(() => {
    onLoad();
  }, [onLoad]);
  const delOpport = (id) => {
    console.log(id);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_id, data) => (
        <p
          key={data?.id}
          className="bg-light position text-dark text-truncate"
          style={{ maxWidth: "150px" }}
        >
          <Tooltip placement="topLeft" title={data?.service?.name}>
            {data?.service?.name}
          </Tooltip>
        </p>
      ),
    },
    {
      title: "Price",
      key: "price",
      dataIndex: "price",
      render: (_id, data) => (
        <p
          className="bg-light position text-dark text-truncate"
          style={{ maxWidth: "350px" }}
        >
          <Tooltip placement="topLeft" title={data?.price}>
            {data?.price}
          </Tooltip>
        </p>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (e) => (
        <Tag color={`${e === "1" ? "success" : "error"}`}>
          {e === "1" ? "Active" : "Inactive"}
        </Tag>
      ),
    },
    {
      title: "Action",
      key: "id",
      dataIndex: "id",
      render: (id, data) => (
        <>
          <Tooltip title="View Service">
            <Button
              type="primary"
              shape="circle"
              icon={<EyeOutlined />}
              onClick={() => viewOpen(data)}
            />
          </Tooltip>
          <Tooltip title="Edit Service">
            <Button
              className="bg-success text-white"
              shape="circle"
              icon={<EditOutlined />}
              onClick={() => editOpen(data)}
            />
          </Tooltip>
          <Tooltip title="Delete Service">
            <Button
              className="bg-danger text-white"
              shape="circle"
              icon={<DeleteOutlined />}
              onClick={() => delOpport(id)}
            />
          </Tooltip>
        </>
      ),
    },
  ];

  return (
    <div style={{ flex: 1 }}>
      {/* <h2>Service Page</h2> */}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <Button
            hidden
            type="primary"
            icon={<FaRegBuilding />}
            size="large"
            onClick={() => setVisible(true)}
          >
            Create
          </Button>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          marginTop: 20,
        }}
        className="kit__utils__table"
      >
        <Table
          columns={columns}
          rowKey="id"
          dataSource={serviceRequests}
          loading={loading}
          className="mb-2"
          pagination={false}
        />
      </div>
      <CreateRequest visible={visible} onHandler={onCloseModal} />
      <EditModal
        edit={edit}
        setEdit={setEdit}
        viewEdit={viewEdit}
        onCloseEdit={onCloseEdit}
        name="Service"
      />
      <ViewModal
        viewOne={viewOne}
        viewOp={viewOp}
        onCloseView={onCloseView}
        name="Service"
      />
    </div>
  );
};

export default ServiceRequest;
