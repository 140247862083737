import React from "react";

// constants
import { ImFolderUpload } from "react-icons/im";
import { BsFillCloudCheckFill } from "react-icons/bs";
import { Spin } from "antd";

const Upload = ({
  title,
  name,
  onChange,
  required,
  completed,
  status,
  loading,
}) => {
  const onFileChoose = (e) => {
    onChange?.(e.currentTarget.files[0]);
  };
  return (
    <div
      onClick={() => document.getElementById(name).click()}
      className="w-full border flex flex-col justify-center items-center cursor-pointer py-5 rounded-xl"
    >
      <label className="block">
        {title}
        {required && <span className="text-[red]"> *</span>}
      </label>
      <ImFolderUpload
        color={status ? "red" : completed ? "green" : "grey"}
        size={80}
        className="block"
      />
      {loading && <Spin />}
      {completed && <BsFillCloudCheckFill color="green" size={20} />}
      <input
        type="file"
        name={name}
        id={name}
        value=""
        className="outline-none"
        onChange={onFileChoose}
        hidden
      />
    </div>
  );
};

export default Upload;
