import React from 'react';
import useCreateCompany from '../hooks/useCreateUser';

// components
import { Button, Form, Input, Modal } from 'antd';
import { Select } from 'antd';

// state
import { useDispatch, useSelector } from 'react-redux';
import { AddUsers2Company, CreateUsers } from '../../../services/apis/user';
import { useNavigate } from 'react-router-dom';


const CreateUser = ({visible, onHandler, companyID}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const companies = useSelector(({company}) => company.companies);
  const loading = useSelector(({company}) => company.loading)
  // const states = useSelector(({country}) => country.states?.states)
  // const countries = useSelector(({country}) => country.all_countries)
  const {data, onLoad, onGetCountry, onChangeText, onValidate} = useCreateCompany();

  const handleOk = () => {
    const validate = onValidate();
    if (!validate) {
      return;
    }
    const formData = {...data, accept_policy: '1', password:'password', password_confirmation: "password", company_id:companyID};
    CreateUsers()
    console.log({...data, ...formData}, dispatch, navigate)
    onHandler?.()
  };

  React.useEffect(() => {
    onLoad();
    onGetCountry();
  }, [onLoad, onGetCountry]);

  return (
    <Modal
        title="Create New Employee"
        open={visible}
        onOk={handleOk}
        confirmLoading={loading}
        onCancel={onHandler}
      >
        <div style={{margin: '15px 0'}}>
          <label>Name: </label>
          <Input 
            placeholder="Full Name" 
            value={data.name} 
            name="name"
            onChange={onChangeText} 
          />
        </div>

        <div style={{margin: '15px 0'}}>
          <label>Phone Number:</label>
          <Input 
            placeholder="Phone Number" 
            value={data.phone} 
            name="phone"
            onChange={onChangeText} 
          />
        </div>

        <div style={{margin: '15px 0'}}>
          <label>Email:</label>
          <Input 
            placeholder="Email" 
            value={data.email}
            name="email"
            onChange={onChangeText} 
          />
        </div>

        <div style={{margin: '15px 0'}}>
          <label>Contact Address</label>
          <Input 
            placeholder="Contact Address" 
            value={data.address}
            name="address"
            onChange={onChangeText}
          />
        </div>
      </Modal>
  )
}

export default CreateUser;

export const UserCompanyModal = ({openD,  onHandler, companies, visible}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [addCom, setAddCom] = React.useState({
    email: '',
    company_id:''
  });
  const loading = useSelector(({user}) => user.loading)
  const onFinish = (formData) => {
    const data = {
      ...formData, 
      email: openD.email
    };
    console.log(data)
    AddUsers2Company({...data, ...formData, email: openD.email }, dispatch, navigate)
    // editOpportunity({...data, ...formData}, dispatch, navigate)
  }
  return (
    <div>
      <Modal      
        title={`Add to Company`}
        visible={visible} 
        onCancel={onHandler} 
        footer={false}
      >
        <div>
          <Form layout="vertical" className="w-100" onFinish={()=>onFinish(addCom)}>
            <div className="row">
              <div>
              {/* name='email' initialValue={openD.email} */}
                <Form.Item label='User Email'>
                  <Input 
                    required 
                    readOnly
                    value={openD.email} onChange={({target:{value}})=>setAddCom({...addCom, email: openD.email})}
                    placeholder="Email" 
                  />
                </Form.Item>
              </div>
              <div>
              {/* name='company_id' */}
              <Form.Item label='Company Name'>
                <Select placeholder="Select Company" value={openD.company_id} onChange={(value)=>setAddCom({...addCom, company_id: value})} size="large" required>
                  {companies.map(dat => (
                    <Select.Option value={dat.id} key={dat.id}>
                      {dat.company_name}
                    </Select.Option>
                    ))}
                </Select>
              </Form.Item>
              </div>
              <div>              
                <Form.Item>
                  <Button
                    htmlType="submit"
                    loading={loading}
                    size="large"
                  >
                    Submit
                  </Button>
                </Form.Item>
              </div>
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  )
}
