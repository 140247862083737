import axiosInstance from "../../utility/axiosInstance";

// state
import { openNotification } from "../../modules";
import { setLoading, disableLoading } from "../../store/slices/loading";
import fetchApi from "../../utility/fetchApi";
import {
  disableTrainingLoading,
  setTrainingLoading,
  setTraining,
} from "../../store/slices/training";

export const getAllTraining = async (dispatch, navigate) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance(navigate).get("/get-trainings");
    if (response.data) {
      const { data } = response.data;
      dispatch(setTraining(data));
    }
  } catch (err) {
    const { data } = err.response;
    openNotification({
      type: "error",
      title: "Incorrect Credentials",
      description: data.message,
    });
  }
  dispatch(disableLoading());
};

export const createTraining = async (formData, dispatch, navigate) => {
  dispatch(setTrainingLoading());
  try {
    const response = await axiosInstance(navigate).post(
      "/create-training",
      formData
    );

    console.log(response.data);
    if (response.data) {
      openNotification({
        type: "success",
        title: "Successful!",
        description: response.data.message,
      });
    }
    setTimeout(() => {
      window.location.reload();
    }, 500);
  } catch (err) {
    const { data } = err.response;
    openNotification({
      type: "error",
      title: "Incorrect Credentials",
      description: data.message,
    });
  }
  dispatch(disableTrainingLoading());
};

export const editTraining = async (data, dispatch, navigate) => {
  dispatch(setTrainingLoading());
  try {
    const response = await axiosInstance(navigate).put(`/edit-training`, data);
    if (response.data) {
      openNotification({
        type: "success",
        title: "Successful!",
        description: response.data.message,
      });
    }
    setTimeout(() => {
      window.location.reload();
    }, 500);
  } catch (err) {
    const { data } = err.response;
    openNotification({
      type: "error",
      title: "Incorrect Credentials",
      description: data.message,
    });
  }
  dispatch(disableTrainingLoading());
};

export const delete_training = async (dispatch, id) => {
  dispatch(setTrainingLoading());
  try {
    await fetchApi.get(`/delete-training/${id}`);
    // console.log(response.data);
  } catch (err) {
    const { data } = err.response;
    console.log(data);
  }
  dispatch(disableTrainingLoading());
};
