import React from 'react'
// import useUpload from '../../../services/hooks/useUpload';
import { useNavigate } from 'react-router-dom';

// components
import { Skeleton } from 'antd';

// state
import {getAuthUser} from '../../../services/apis/auth';
import { useDispatch, useSelector } from 'react-redux'
// import { setUser } from '../../../store/slices/auth';

const DashBoard = () => {
  const dispatch = useDispatch();
  const loading = useSelector(({loading}) => loading.loading);
  // const [file, setFile] = React.useState(null);
  // const {uploadFile} = useUpload();
  const navigate = useNavigate();
  const onLoad = React.useCallback(async () => {
    getAuthUser(dispatch,  navigate);
  }, [dispatch, navigate])

  React.useEffect(() => {
    onLoad()
  }, [onLoad]);
  return (
    <>
      <Skeleton.Button active={loading} style={{width: '200px', height: '200px'}} />
      {/* <form action={} metho encType='multipart/form-data'> */}
        {/* <input type="file" name="file" onChange={a => setFile(a.target.files[0])} placeholder="Please Select a File for Opportunity" />
        <button type="button" onClick={async () => await uploadFile(file)}>Submit</button> */}
      {/* </form> */}
    </>
  )
}

export default DashBoard
