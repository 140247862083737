import React from "react";

// router
import { useNavigate } from "react-router-dom";

// state
import { useDispatch } from "react-redux";
import { getAllTraining } from "../../../services/apis/training";
import { getAllCountry, getState } from "../../../services/apis/country";

const useCreateTraining = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [name, setName] = React.useState("");
  // const [file, setFile] = React.useState('')
  const [image] = React.useState("");
  const [date, setDate] = React.useState("");
  const [data, setData] = React.useState({
    name: "",
    body: "",
    address: "",
    link: "",
    date: "",
    duration: "",
    amount: "",
    image: "",
    state_id: "",
  });

  const [error, setError] = React.useState({
    name: "",
    body: "",
    address: "",
    link: "",
    date: "",
    amount: "",
    duration: "",
    state_id: "",
  });

  const onChangeText = (text) => {
    const { value, name } = text.target;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const onChange = (date, dateString) => {
    setDate(dateString);
  };

  const onValidate = () => {
    let err;
    if (!data.name && !name) {
      err = true;
      setError((prev) => ({ ...prev, name: "warning" }));
    }
    if (!data.body) {
      err = true;
      setError((prev) => ({ ...prev, body: "warning" }));
    }
    if (!data.state_id) {
      err = true;
      setError((prev) => ({ ...prev, state_id: "warning" }));
    }
    if (!data.state_id) {
      err = true;
      setError((prev) => ({ ...prev, state_id: "warning" }));
    }
    if (!data.image) {
      err = true;
      setError((prev) => ({ ...prev, image: "warning" }));
    }
    if (err) {
      return false;
    }
    return true;
  };

  const getStateCountry = (id) => {
    getState(id, dispatch, navigate);
  };

  const getStateTraining = (id) => {
    getState(id, dispatch, navigate);
  };

  const onLoad = React.useCallback(() => {
    getAllTraining(dispatch, navigate);
  }, [dispatch, navigate]);

  // const onGetCountry = React.useCallback(() => {
  //   getAllTraining(dispatch, navigate)
  // }, [dispatch, navigate]);

  const onGetCountry = React.useCallback(() => {
    getAllCountry(dispatch, navigate);
  }, [dispatch, navigate]);

  return {
    data,
    setData,
    error,
    getStateCountry,
    setError,
    date,
    image,
    onChange,
    onLoad,
    onGetCountry,
    getStateTraining,
    name,
    setName,
    onChangeText,
    onValidate,
  };
};

export default useCreateTraining;
