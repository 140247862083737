import React, { useState } from "react";
import useUploadFiles from "../../../services/hooks/useUploadFiles";
import useCreateOpportunity from "../hooks/useCreateOpportunity";

// components
import {
  Input,
  Select,
  Modal,
  Form,
  Button,
  DatePicker,
  Image,
  Tag,
} from "antd";
import Upload from "../../../component/modals/upload";
import moment from "moment";
// state
import { useDispatch } from "react-redux";
// import { createCompany } from '../../../services/apis/company';
import { useNavigate } from "react-router-dom";
import {
  createOpportunity,
  editOpportunity,
} from "../../../services/apis/opportunity";
import useUpload from "../../../services/hooks/useUpload";
import {
  CloseOutlined,
  DownloadOutlined,
  EyeOutlined,
} from "@ant-design/icons";

const CreateOpportunity = ({ visible, onHandler, oppType }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { uploadAvatar, uploadFile, loading: imageLoader } = useUploadFiles();
  const {
    data,
    error,
    onLoad,
    onGetCountry,
    setData,
    onChangeText,
    onGetOpportunityType,
    onValidate,
    setError,
  } = useCreateOpportunity();
  const [loading] = useState(false);
  const handleOk = () => {
    const validate = onValidate();
    if (!validate) {
      return;
    }
    createOpportunity(data, dispatch, navigate);
    onHandler?.();
  };

  React.useEffect(() => {
    onLoad();
    onGetCountry();
    onGetOpportunityType();
  }, [onLoad, onGetCountry, onGetOpportunityType]);

  return (
    <Modal
      title="Add Oppotunity"
      open={visible}
      onOk={handleOk}
      confirmLoading={loading}
      onCancel={onHandler}
    >
      <Form layout="vertical" className="w-100">
        <div className="row">
          <div style={{ margin: "15px 0" }}>
            <Form.Item label="Opportunity Title">
              <Input
                placeholder="Opportunity Title"
                value={data.opportunity_name}
                name="opportunity_name"
                onChange={onChangeText}
              />
            </Form.Item>
          </div>
          <div style={{ margin: "15px 0" }}>
            <Form.Item label="Opportunity Description">
              <Input
                placeholder="Opportunity Description"
                value={data.opportunity_description}
                name="opportunity_description"
                onChange={onChangeText}
              />
            </Form.Item>
          </div>
          <div className="col-md-6" style={{ margin: "15px 0" }}>
            <Form.Item label="Opportunity Type">
              <Select
                placeholder="Opportunity Type"
                value={data.opportunity_type}
                // name="opportunity_type"
                // onChange={onChangeText}
                onChange={(value) => {
                  setData({ ...data, opportunity_type: value });
                }}
              >
                {oppType.map((d) => (
                  <Select.Option value={d.id} key={d.id}>
                    {d.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div className="col-md-6" style={{ margin: "15px 0" }}>
            <Form.Item label="Select Expire Date">
              <DatePicker
                onChange={(date, dateString) => {
                  setError((prev) => ({ ...prev, expire_date: "" }));
                  setData((prev) => ({ ...prev, expire_date: dateString }));
                }}
                format="YYYY-MM-DD HH:mm:ss"
                showTime={{
                  defaultValue: moment("00:00:00", "HH:mm:ss"),
                }}
              />
            </Form.Item>
          </div>

          <div className="my-2">
            <Upload
              title="Upload opportunity File"
              name="opportunity_file"
              loading={imageLoader}
              status={error.opportunity_file}
              completed={data.opportunity_file}
              onChange={async (res) => {
                setError((prev) => ({ ...prev, opportunity_file: "" }));
                const response = await uploadFile(res);
                if (typeof response === "string") {
                  setData((prev) => ({ ...prev, opportunity_file: response }));
                } else {
                  setError((prev) => ({ ...prev, opportunity_file: true }));
                }
              }}
            />
          </div>

          <div className="my-2">
            <Upload
              title="Upload Image"
              name="opportunity_image"
              loading={imageLoader}
              status={error.opportunity_image}
              completed={data.opportunity_image}
              onChange={async (res) => {
                setError((prev) => ({ ...prev, opportunity_image: "" }));
                const response = await uploadAvatar(res);
                if (typeof response === "string") {
                  setData((prev) => ({ ...prev, opportunity_image: response }));
                } else {
                  setError((prev) => ({ ...prev, opportunity_image: true }));
                }
              }}
            />
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default CreateOpportunity;

export const EditModal = ({ name, edit, setEdit, onCloseEdit, viewEdit }) => {
  const { uploadFile } = useUpload();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [uploadType, setUploadType] = useState("");
  const [uplodImage, setUploadImage] = useState("");
  const [date, setDate] = useState("");
  const onChange = (date, dateString) => {
    setDate(dateString);
  };
  const onFinish = (data) => {
    const formData = {
      ...data,
      opportunity_image:
        uplodImage !== "" ? uplodImage : data.opportunity_image,
      opportunity_file: uploadType !== "" ? uploadType : data.opportunity_file,
      expire_date: date !== "" ? date : data.expire_date,
    };
    editOpportunity({ ...data, ...formData }, dispatch, navigate);
  };
  return (
    <div>
      <Modal
        title={`Edit ${name}`}
        visible={viewEdit}
        onCancel={onCloseEdit}
        footer={false}
      >
        <div>
          <Form
            layout="vertical"
            className="w-100"
            onFinish={() => onFinish(edit)}
          >
            <div className="row">
              <div style={{ margin: "15px 0" }}>
                <Form.Item label="Edit Opportunity Title">
                  <Input
                    required
                    placeholder="Enter Opportunity Title"
                    value={edit.opportunity_name}
                    onChange={({ target: { value } }) =>
                      setEdit({ ...edit, opportunity_name: value })
                    }
                  />
                </Form.Item>
              </div>
              <div style={{ margin: "15px 0" }}>
                <Form.Item label="Edit Opportunity Description">
                  <Input
                    required
                    placeholder="Enter Opportunity Description"
                    value={edit.opportunity_description}
                    onChange={({ target: { value } }) =>
                      setEdit({ ...edit, opportunity_description: value })
                    }
                  />
                </Form.Item>
              </div>
              <div className="col-md-6" style={{ margin: "15px 0" }}>
                <Form.Item label="Edit Opportunity Type">
                  <Input
                    required
                    placeholder="Enter Opportunity Type"
                    value={edit.opportunity_type}
                    onChange={({ target: { value } }) =>
                      setEdit({ ...edit, opportunity_type: value })
                    }
                  />
                </Form.Item>
              </div>
              <div className="col-md-6" style={{ margin: "15px 0" }}>
                <Form.Item label="Select Expire Date">
                  <DatePicker
                    // value={edit.expire_date}
                    onChange={onChange}
                    format="YYYY-MM-DD HH:mm:ss"
                    defaultValue={moment(edit.expire_date)}
                    showTime={{
                      defaultValue: moment(edit.expire_date),
                    }}
                  />
                </Form.Item>
              </div>
              <div style={{ margin: "15px 0" }}>
                <Form.Item required label="Upload File">
                  <Input
                    type="file"
                    placeholder="file"
                    value={edit.opportunity_file}
                    onChange={({ target: { files } }) => {
                      const file = files[0];
                      uploadFile({
                        file,
                        setFile: setUploadType,
                        setLoading,
                        dispatch,
                      });
                    }}
                  />
                </Form.Item>
              </div>
              <div style={{ margin: "15px 0" }}>
                <Form.Item required label="Upload Image">
                  <Input
                    type="file"
                    value={edit.opportunity_image}
                    placeholder="Image"
                    onChange={({ target: { files } }) => {
                      const file = files[0];
                      uploadFile({
                        file,
                        setFile: setUploadImage,
                        setLoading,
                        dispatch,
                      });
                    }}
                  />
                </Form.Item>
              </div>
              <div style={{ margin: "15px 0" }}>
                <Form.Item>
                  <Button htmlType="submit" loading={loading} size="large">
                    Update
                  </Button>
                </Form.Item>
              </div>
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

export const ViewModal = ({ name, onCloseView, viewOp, viewOne }) => {
  const [viewFile, setViewFile] = useState(false);
  const onView = () => {
    setViewFile(true);
  };
  const closeView = () => {
    setViewFile(false);
  };
  return (
    <div>
      <Modal
        title={`View ${name}`}
        visible={viewOne}
        onCancel={onCloseView}
        footer={false}
      >
        <div className="row">
          <div>
            <Image width={200} src={viewOp?.opportunity_image} />
            <h4>Title: {viewOp?.opportunity_name}</h4>
            <p>Description: {viewOp?.opportunity_description}</p>
            <p>Type: {viewOp?.opportunity_type}</p>
            <p>
              <Tag color="success">Active</Tag>
            </p>
            <Button onClick={onView}>
              <EyeOutlined /> View File
            </Button>
            {viewFile && (
              <>
                <CloseOutlined onClick={closeView} />
                <iframe
                  title="ppt"
                  className="w-100 h-100"
                  src={viewOp?.opportunity_file}
                />
              </>
            )}
            <a
              className="mx-2"
              href={viewOp?.opportunity_file}
              download
              style={{ border: "solid 1px", padding: 5 }}
            >
              <DownloadOutlined /> Download File
            </a>
          </div>
        </div>
      </Modal>
    </div>
  );
};
