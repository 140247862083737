import React from 'react'

// navigator
import { useNavigate } from 'react-router-dom';

// statse
import { useDispatch } from 'react-redux'
import { getState } from '../../../services/apis/country';

const useDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onLoad = React.useCallback((id) => {
    getState(id, dispatch, navigate)
  }, [dispatch, navigate]);
  return {onLoad}
}

export default useDetails
