import React from "react";
import useDashboard from "../hooks/useDashboard";

// components
// import CreateBlog, { EditModal, ViewModal } from "../misc/CreateBlog";
import { ViewModal } from "../misc/CreateBlog";
import CreateBlog from "../create";
import TableBody from "../../../component/ui/table/TableBody";
import TableHeader from "../../../component/ui/table/TableHeader";
import EditBlog from "../edit";
import { Button, Spin, Tooltip } from "antd";

// constants and icon
import { real_date } from "../../../services/convert/date";
import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";

// router
import { Link, useNavigate } from "react-router-dom";

// state and service
import { useDispatch, useSelector } from "react-redux";
import { getAllBlog, destroy_blog } from "../../../services/apis/blog";

const Blog = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const blogs = useSelector(({ blog }) => blog?.blogs);
  const loading = useSelector(({ blog }) => blog?.loading);
  const [visible, setVisible] = React.useState(false);

  const onCloseModal = () => {
    setVisible(false);
  };
  const {
    editOpen,
    viewOpen,
    edit,
    viewEdit,
    onCloseView,
    viewOne,
    viewOp,
    onCloseEdit,
  } = useDashboard();

  const onLoad = React.useCallback(() => {
    getAllBlog(dispatch, navigate);
  }, [dispatch, navigate]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div style={{ flex: 1 }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <Button type="primary" onClick={() => setVisible(true)} size="large">
            Create Blog
          </Button>
        </div>
        <div>
          <Link to="/blog-cat">
            <Button type="primary" icon={<EyeOutlined />} size="large">
              Blog Categories
            </Button>
          </Link>
          <Link className="mx-2" to="/blog-tag">
            <Button type="primary" icon={<EyeOutlined />} size="large">
              Blog Tags
            </Button>
          </Link>
        </div>
      </div>

      <TableHeader>
        <div className="border-r-2 text-center w-full">Title</div>
        <div className="border-r-2 text-center w-full">Category</div>
        <div className="border-r-2 text-center w-full">Created</div>
        <div className="border-r-2 text-center w-full">Status</div>
        <div className=" text-center w-full">Action</div>
      </TableHeader>

      {loading ? (
        <Spin />
      ) : (
        blogs.map((item) => (
          <TableBody key={item?.id}>
            <div className="text-center w-full border-r-2">{item?.title}</div>
            <div className="text-center w-full border-r-2">
              {item?.category?.category_name}
            </div>
            <div className="text-center w-full border-r-2">
              {item?.created_at ? real_date(item?.created_at) : ""}
            </div>
            <div className="text-center w-full border-r-2">
              {item?.position ? "active" : "not active"}
            </div>
            <div className="flex w-full justify-evenly">
              <Tooltip
                title="View Blog"
                className="cursor-pointer bg-blue-600 p-1 rounded-full"
              >
                <EyeOutlined
                  onClick={() => viewOpen(item)}
                  className="text-white"
                />
              </Tooltip>
              <Tooltip
                title="Edit Blog"
                className="cursor-pointer bg-green-500 p-1 rounded-full"
              >
                <EditOutlined
                  onClick={() => editOpen(item)}
                  className="text-white"
                />
              </Tooltip>
              <Tooltip
                title="Delete Blog"
                className="cursor-pointer bg-red-500 p-1 rounded-full"
              >
                <DeleteOutlined
                  onClick={() => destroy_blog(dispatch, { id: item?.id })}
                  className="text-white"
                />
              </Tooltip>
            </div>
          </TableBody>
        ))
      )}

      <CreateBlog visible={visible} onHandler={onCloseModal} />
      <EditBlog blog={edit} visible={viewEdit} onHandler={onCloseEdit} />

      {/* <EditModal
        edit={edit}
        setEdit={setEdit}
        viewEdit={viewEdit}
        onCloseEdit={onCloseEdit}
      /> */}

      <ViewModal
        viewOne={viewOne}
        viewOp={viewOp}
        onCloseView={onCloseView}
        // name='Blog'
      />
    </div>
  );
};

export default Blog;
