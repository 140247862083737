import React from 'react'
import useHome from '../hooks/useHome';

// components
import { Button, Table } from 'antd';

// constants and iconsy7ifghknk ,ON
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

// state
import { useSelector } from 'react-redux'

const Country = () => {
  const countries = useSelector(({country}) => country.countries);
  const last_page = useSelector(({country}) => country.last_page);
  const current_page = useSelector(({country}) => country.current_page);
  const loading = useSelector(({loading}) => loading.loading);
  const {page, setPage, columns, onLoad} = useHome();

  React.useEffect(() => {
    onLoad();
  }, [onLoad])

  return (
    <div className='container'>
      <Table columns={columns.current} dataSource={countries} loading={loading} pagination={false} rowKey="id" />
      <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', paddingTop: 20, paddingBottom: 20}}>
        <Button
          type="primary"
          icon={<FaArrowLeft />}
          loading={loading}
          onClick={() => page > 1 && setPage(page - 1)}
        >
          {' '}Prev
        </Button>

        <span style={{paddingLeft: 10, paddingRight: 10}}>{current_page} | {last_page}</span>

        <Button
          type="primary"
          icon={<FaArrowRight />}
          loading={loading}
          onClick={() => page <= last_page && setPage(page + 1)}
        >
          {' '}Next
        </Button>
      </div>
    </div>
  )
}

export default Country
