import { configureStore } from '@reduxjs/toolkit'

// slices
import auth from './slices/auth'
import country from './slices/country';
import opportunity from './slices/opportunity';
import training from './slices/training';
import loading from './slices/loading';
import company from './slices/company';
import blog from './slices/blog'
import user from './slices/user';
import companiesFile from './slices/companiesFile';
import service from './slices/services';
import serviceRequest from './slices/serviceRequest';

export default configureStore({
  reducer: {
    auth, loading, blog, serviceRequest, companiesFile, service, training, country, opportunity, company, user
  },
})