import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  opportunities: [],
  opportunityType:[],
  search: [],
  loading: false,
  opportunity: null,
  last_page: null,
  current_page: null,
  upload:{}
}

export const opportunity = createSlice({
  name: 'opportunity',
  initialState,
  reducers: {
    setOpportunities: (state, action) => {
      state.opportunities = action.payload.data;
      state.last_page = action.payload.last_page
      state.current_page = action.payload.current_page
  },
  setOpportunity: (state, action) => {
        state.opportunities = action.payload;
    },
    setOpportunityTypes: (state, action) => {
          state.opportunityType = action.payload;
      },
    setUpload: (state, action) => {
          state.upload = action.payload;
      },
    setSearch: (state, action) => {
        state.opportunities = action.payload.data;
    },
    setOpportunityLoading: (state) => {
        state.loading = true;
    },
    disableOpportunityLoading: (state) => {
        state.loading = false;
    }
  },
})

// Action creators are generated for each case reducer function
export const { setSearch, setUpload, setOpportunityTypes, setOpportunity, setOpportunityLoading, disableOpportunityLoading, setOpportunities } = opportunity.actions

export default opportunity.reducer