import React from "react";
import useDashboard from "../hooks/useDashboard";

// components
import CreateCompany from "../misc/CreateCompany";
import { FaRegBuilding } from "react-icons/fa";
import { DatePicker, Modal, Table, Tag } from "antd";
import { Input } from "antd";
import { Button } from "antd";
// import { Collapse } from 'antd';

// constants and icon
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

// state
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { GoEye } from "react-icons/go";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { ActDecactCompany } from "../../../services/apis/company";

const { RangePicker } = DatePicker;
const { Search } = Input;
const { confirm } = Modal;

const Company = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const companies = useSelector(({ company }) => company.companies);
  const last_page = useSelector(({ company }) => company.last_page);
  const current_page = useSelector(({ company }) => company.current_page);
  const loading = useSelector(({ loading }) => loading.loading);
  const {
    onLoad,
    setPage,
    visible,
    setVisible,
    onCloseModal,
    onSearch,
    onChange,
    page,
  } = useDashboard();
  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  // const info = (d) => {
  //   console.log(d)
  //   Modal.info({
  //     title: `Do you want to ${d.status === '1' ? 'Deactivate': 'Activate'} this Company ?`,
  //     content: (
  //       <div>
  //         <h4>{d.company_name}</h4>
  //       </div>
  //     ),
  //     onOk() {},
  //   });
  // };

  const info = ({ id, status, company_name }) => {
    confirm({
      title: `Do you want to ${
        status === "1" ? "Deactivate" : "Activate"
      } this Company ?`,
      icon: <ExclamationCircleOutlined />,
      content: <h4>{company_name}</h4>,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        ActDecactCompany(id, dispatch, navigate);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "company_name",
      key: "company_name",
      // render: (e, data) => (
      //   <p>{data.profile.name}</p>
      // ),
    },
    {
      title: "Email",
      dataIndex: "company_email",
      key: "company_email",
    },
    {
      title: "Phone",
      dataIndex: "company_phone",
      key: "company_phone",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (e, data) => (
        <>
          {data.status === "0" ? (
            <Tag color="red">Unactive</Tag>
          ) : (
            <Tag color="green">Active</Tag>
          )}
        </>
      ),
    },
    {
      title: "ACTION",
      key: "action",
      render: (da, item) => (
        <div className="flex items-center">
          <Link to={`/company/details/${item.id}`} state={item}>
            <Button style={{ marginLeft: 2 }} type="primary">
              <div className="flex justify-center items-center w-full">
                <GoEye /> View
              </div>
            </Button>
          </Link>
          <Button
            style={{
              marginLeft: 2,
              background: item.status === "1" ? "red" : "blue",
              border: item.status === "1" ? "red" : "blue",
            }}
            type="primary"
            onClick={() => info(item)}
          >
            {item.status === "1" ? "Deactivate" : "Activate"}
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div style={{ flex: 1 }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <Button type="primary" size="large" onClick={() => setVisible(true)}>
            <div className="flex justify-center items-center w-full">
              <FaRegBuilding className="mr-1" /> Create
            </div>
          </Button>
        </div>
        <div>
          <RangePicker onChange={onChange} />
        </div>
        <div>
          <Search
            placeholder="Search Company..."
            onSearch={onSearch}
            onChange={(text) => console.log(text.target.value)}
            style={{
              width: 250,
            }}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          marginTop: 20,
        }}
        className="kit__utils__table"
      >
        <Table
          columns={columns}
          rowKey="id"
          dataSource={companies && companies}
          loading={loading}
          className="mb-2"
          pagination={false}
        />

        <div className="flex w-full items-center justify-end py-[20px]">
          <Button
            type="primary"
            loading={loading}
            onClick={() => page > 1 && setPage(page - 1)}
          >
            <div className="flex justify-center items-center w-full">
              <FaArrowLeft className="mr-1" /> Prev
            </div>
          </Button>

          <span style={{ paddingLeft: 10, paddingRight: 10 }}>
            {current_page} | {last_page}
          </span>

          <Button
            type="primary"
            loading={loading}
            onClick={() => page <= last_page && setPage(page + 1)}
          >
            <div className="flex justify-center items-center w-full">
              Next
              <FaArrowRight className="mr-1" />
            </div>
          </Button>
        </div>
      </div>
      <CreateCompany visible={visible} onHandler={onCloseModal} />
    </div>
  );
};

export default Company;
