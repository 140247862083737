import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  companiesFiles: [],
  search: [],
  loading: false,
  companiesFile: null,
  last_page: null,
  current_page: null,
  upload:{}
}

export const companiesFile = createSlice({
  name: 'companiesFile',
  initialState,
  reducers: {
    setCompaniesFiles: (state, action) => {
      state.companiesFiles = action.payload.data;
      state.last_page = action.payload.last_page
      state.current_page = action.payload.current_page
  },
  setCompaniesFile: (state, action) => {
        state.companiesFiles = action.payload;
    },
    setUpload: (state, action) => {
          state.upload = action.payload;
      },
    setSearch: (state, action) => {
        state.companiesFiles = action.payload.data;
    },
    setCompaniesFileLoading: (state) => {
        state.loading = true;
    },
    disableCompaniesFileLoading: (state) => {
        state.loading = false;
    }
  },
})

// Action creators are generated for each case reducer function
export const { setSearch, setUpload, setCompaniesFile, setCompaniesFileLoading, disableCompaniesFileLoading, setCompaniesFiles } = companiesFile.actions

export default companiesFile.reducer