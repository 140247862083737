import React from "react";

// router
import { useNavigate } from "react-router-dom";

// state
import { useDispatch } from "react-redux";
import { getAllService } from "../../../services/apis/services";
import { getState } from "../../../services/apis/country";

const useCreateService = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [name, setName] = React.useState("");
  // const [file, setFile] = React.useState('')
  const [image] = React.useState("");
  const [date, setDate] = React.useState("");
  const [data, setData] = React.useState({
    name: "",
    description: "",
    packages: [],
  });

  const [error, setError] = React.useState({
    name: "",
    description: "",
    packages: [],
  });

  const onChangeText = (text) => {
    const { value, name } = text.target;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const onChange = (date, dateString) => {
    setDate(dateString);
  };

  const onValidate = () => {
    let err;
    if (!data.name) {
      err = true;
    }
    if (!data.description) {
      err = true;
    }
    if (data.packages.length < 1) {
      err = true;
    }
    if (err) {
      return false;
    }
    return true;
  };

  const getStateService = (id) => {
    getState(id, dispatch, navigate);
  };

  const onLoad = React.useCallback(() => {
    getAllService(dispatch, navigate);
  }, [dispatch, navigate]);

  const onGetCountry = React.useCallback(() => {
    getAllService(dispatch, navigate);
  }, [dispatch, navigate]);

  return {
    data,
    name,
    date,
    error,
    image,
    setData,
    setError,
    onChange,
    onLoad,
    onGetCountry,
    getStateService,
    setName,
    onChangeText,
    onValidate,
  };
};

export default useCreateService;
