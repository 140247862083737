import axios from "axios";
import baseURL from "../config/baseURL";

let headers = {};

const axiosInstance = (history) => {
  const axiosInstance = axios.create({
    baseURL,
    headers,
  });

  axiosInstance.interceptors.request.use(
    async (config) => {
      const serial = localStorage.getItem("@serial");
      if (serial) {
        config.headers.Authorization = `bearer ${serial}`;
      }
      config.headers.Accept = "application/json";
      config.headers["Content-Type"] = "multipart/form-data";

      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (response) =>
      new Promise((resolve, reject) => {
        resolve(response);
      }),
    (error) => {
      if (!error.response) {
        //error not from server
        return new Promise((_resolve, reject) => {
          reject(error);
        });
      }
      if (error.response?.status === 401 || error.response?.status === 403) {
        localStorage.removeItem("@serial");
        history ? history("/login") : (window.location.href = "/login");
        return new Promise((_resolve, reject) => {
          reject(error);
        });
      } else {
        return new Promise((_resolve, reject) => {
          reject(error);
        });
      }
    }
  );

  return axiosInstance;
};

export default axiosInstance;
