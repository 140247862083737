import React from 'react'

// router
import { useNavigate } from 'react-router-dom';

// state
import { useDispatch } from 'react-redux';
import { getAllCompaniesFile } from '../../../services/apis/companiesFile';
import { getState } from '../../../services/apis/country';

const useCreateCompaniesFile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [name, setName] = React.useState('')
  // const [file, setFile] = React.useState('')
  const [image, ] = React.useState('')
  const [date, setDate] = React.useState('')
  const [data, setData] = React.useState({
    name: "",
    body: "",
    address: "",
    link: "",
    date: "",
    duration: "",
    amount: "",
    image: "",
    state_id: "",
  })

  const [error, setError] = React.useState({
    name: "",
    body: "",
    address: "",
    link: "",
    date: "",
    amount: "",
    duration: "",
    state_id: "",
    // opportunity_image: "",
  })

  const onChangeText = (text) => {
    const {value, name} = text.target;
    setData(prev => ({...prev, [name]: value}));
  }
  
  const onChange = (date, dateString) => {
    setDate(dateString)
  }

  const onValidate = () => {
    let err;
    if (!data.name && !name) {
      err = true;
    }
    // if (!data.opportunity_file) {
    //   err = true;
    // }
    if (!data.body) {
      err = true;
    }
    // if (!data.expire_date) {
    //   err = true;
    // }
    // if (!data.opportunity_image) {
    //   err = true;
    // }
    if (err) {
      return false;
    }
    return true;
  }

  const getStateCompaniesFile = (id) => {
    getState(id, dispatch, navigate);
  }

  const onLoad = React.useCallback(() => {
    getAllCompaniesFile(dispatch, navigate);
  }, [dispatch, navigate])

  const onGetCountry = React.useCallback(() => {
    getAllCompaniesFile(dispatch, navigate)
  }, [dispatch, navigate]);

  return {data, setData, error, setError, date, image, onChange, onLoad, onGetCountry, getStateCompaniesFile, name, setName, onChangeText, onValidate}
}

export default useCreateCompaniesFile
   