import React from "react";

const TableBody = ({ children }) => {
  return (
    <div className="w-full flex justify-between items-center border-b py-2 mb-[3px] text-[15px]">
      {children}
    </div>
  );
};

export default TableBody;
