import { Tooltip } from 'antd'
import React from 'react'

function DataDisplay({title, detail, border, className, align}) {
  return (
    <li className={`list-group-item d-flex justify-content-between bg-transparent ${border && "border-0"} ${className}`}>
      <div className='w-50 text-start'>
        <span className='text-start'>{title || "---"} : </span>
      </div>
      <div className='w-50'>
      <Tooltip title={detail}>
        <p className={`text-${align || "end"} m-0 fw-bold text-truncate`}>{detail || "---"}</p>
      </Tooltip>
      </div>
    </li>
  )
}

export default DataDisplay