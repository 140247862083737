import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  serviceRequests: [],
  search: [],
  loading: false,
  serviceRequest: null,
  last_page: null,
  current_page: null,
}

export const serviceRequest = createSlice({
  name: 'serviceRequest',
  initialState,
  reducers: {
    setServiceRequests: (state, action) => {
      state.serviceRequests = action.payload.data;
      state.last_page = action.payload.last_page
      state.current_page = action.payload.current_page
  },
  setServiceRequest: (state, action) => {
        state.serviceRequests = action.payload;
    },
    setSearch: (state, action) => {
        state.serviceRequests = action.payload.data;
    },
    setServiceRequestLoading: (state) => {
        state.loading = true;
    },
    disableServiceRequestLoading: (state) => {
        state.loading = false;
    }
  },
})

// Action creators are generated for each case reducer function
export const { setSearch, setServiceRequest, setServiceRequestLoading, disableServiceRequestLoading, setServiceRequests } = serviceRequest.actions

export default serviceRequest.reducer