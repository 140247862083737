import { Button} from 'antd';
import React from 'react'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom';
import { GoEye } from "react-icons/go";

// state
import { getCountry } from '../../../services/apis/country'
const useHome = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = React.useState(1);

  const columns = React.useRef([
    {
      title: 'ABBREVIATION',
      dataIndex: 'abb',
      key: 'abb',
      width: 100,
      columnWidth: 100,
    },
    {
      title: 'COUNTRY',
      dataIndex: 'name',
      key: 'name',
      width: 300,
    },
    {
      title: 'ACTION',
      key: 'action',
      dataIndex: 'id',
      width: 100,
      render: (_, item) => (
        <Link to={{pathname: `/country/${_}`, state: item}}>
          <Button type="primary" icon={<GoEye />} size={15} style={{}}>
            {' '}View
          </Button>
        </Link>
      ),
    },
  ]);

  const onLoad = React.useCallback(() => {
    getCountry(dispatch, navigate, page)
  }, [dispatch, navigate, page])

  return {page, setPage, columns, onLoad}
}

export default useHome
