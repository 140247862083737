import { ExclamationCircleFilled } from '@ant-design/icons'
import { Modal } from 'antd'
/* eslint-disable camelcase */
import React from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SetAdminCompany } from '../services/apis/user';
import ActionButton from './actionButton'
const { confirm } = Modal;

// const userData = store.get("zero-user")

function SetAdminLog({
  // loading,
  data, 
  company,
  email,
  // profile
}) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
  const showConfirm = () => {
    const formData = {
        email:''
    }
    confirm({
      title: `Do you want to Set ${email} as ${company.company_name} admin ?`,
      icon: <ExclamationCircleFilled />,
      content: ``,
      onOk() {
        console.log('OK');
        SetAdminCompany({...data, ...formData, email: email }, dispatch, navigate)
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  return (
    <div className='w-100'>
      <ActionButton
        title="Set as Admin" 
        handle={showConfirm}
        img="/statement.png"
        visible
      />
    </div>
  )
}

export default SetAdminLog