import { Breadcrumb } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'

function PageHeading({
  // img,
  link,
  title,
  name,
  width,
  wClass,
  // handlePage
}) {
  return (
    <div style={{width}} className={wClass}>
      {/* <button type='button' className="cui__utils__heading border-0 bg-transparent" onClick={handlePage}>
        {
          img && (
            <img className='me-4' alt='zero' src={img} />
          )
        }
        <strong className='text-capitalize'>{title || "Zero"}</strong>
      </button> */}
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to={link}>
            {name}
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <strong className='text-capitalize'>{title || " "}</strong>
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className='w-100 bg-light'>
        <div className='rounded-15p p-1 bg-brand w-25' />
      </div>
    </div>
  )
}

export default PageHeading