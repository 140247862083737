import React from "react";
import useDashboard from "../hooks/useDashboard";
// state
import { useSelector } from "react-redux";

// components
import CreateOpportunity, {
  ViewModal,
  EditModal,
} from "../misc/CreateOportunity";
import { FaRegBuilding } from "react-icons/fa";
import { Table, Tag, Tooltip, Button } from "antd";

// constants and icon
import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const Opportunity = () => {
  const opportunity = useSelector(({ opportunity }) => opportunity);
  const { opportunities, opportunityType } = opportunity;
  const loading = useSelector(({ loading }) => loading.loading);
  const {
    onLoad,
    editOpen,
    viewOpen,
    setEdit,
    edit,
    viewEdit,
    onCloseView,
    viewOne,
    viewOp,
    onCloseEdit,
    visible,
    setVisible,
    onCloseModal,
  } = useDashboard();

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  const delOpport = (id) => {
    console.log(id);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "opportunity_name",
      key: "opportunity_name",
      render: (e) => (
        <p
          className="bg-light position text-dark text-truncate"
          style={{ maxWidth: "150px" }}
        >
          <Tooltip placement="topLeft" title={e}>
            {e}
          </Tooltip>
        </p>
      ),
    },
    {
      title: "Description",
      key: "opportunity_description",
      dataIndex: "opportunity_description",
      render: (e) => (
        <p
          className="bg-light position text-dark text-truncate"
          style={{ maxWidth: "250px" }}
        >
          <Tooltip placement="topLeft" title={e}>
            {e}
          </Tooltip>
        </p>
      ),
    },
    // {
    //   title: "Type",
    //   dataIndex: "opportunity_type",
    //   key: "opportunity_type",
    //   render: (e, data) => {
    //     console.log(data);
    //     return (
    //       <p
    //         className="bg-light position text-dark text-truncate"
    //         style={{ maxWidth: "250px" }}
    //       >
    //         <Tooltip placement="topLeft" title={e}>
    //           {e}
    //         </Tooltip>
    //       </p>
    //     );
    //   },
    // },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (e) => (
        <Tag color={`${e === "1" ? "success" : "error"}`}>
          {e === "1" ? "Active" : "Inactive"}
        </Tag>
      ),
    },
    {
      title: "Action",
      key: "id",
      dataIndex: "id",
      render: (id, data) => (
        <>
          <Tooltip title="View Opportunity">
            <Button
              type="primary"
              shape="circle"
              icon={<EyeOutlined />}
              onClick={() => viewOpen(data)}
            />
          </Tooltip>
          <Tooltip title="Edit Opportunity">
            <Button
              className="bg-success text-white"
              shape="circle"
              icon={<EditOutlined />}
              onClick={() => editOpen(data)}
            />
          </Tooltip>
          <Tooltip title="Delete Opportunity">
            <Button
              className="bg-danger text-white"
              shape="circle"
              icon={<DeleteOutlined />}
              onClick={() => delOpport(id)}
            />
          </Tooltip>
        </>
      ),
    },
  ];

  return (
    <div style={{ flex: 1 }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <Button type="primary" size="large" onClick={() => setVisible(true)}>
            <div className="flex items-center">
              <FaRegBuilding /> Create
            </div>
          </Button>
        </div>
        <div>
          <Link to="/opportunity-type">
            <Button type="primary" size="large">
              <div className="flex items-center">
                <EyeOutlined />
                Opportunity Type
              </div>
            </Button>
          </Link>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          marginTop: 20,
        }}
        className="kit__utils__table"
      >
        <h2>Opportunity Page</h2>
        <Table
          columns={columns}
          rowKey="id"
          dataSource={opportunities}
          loading={loading}
          className="mb-2"
          pagination={false}
        />
      </div>
      <CreateOpportunity
        visible={visible}
        onHandler={onCloseModal}
        oppType={opportunityType}
      />
      <EditModal
        edit={edit}
        setEdit={setEdit}
        oppType={opportunityType}
        viewEdit={viewEdit}
        onCloseEdit={onCloseEdit}
        name="Opportunity"
      />
      <ViewModal
        viewOne={viewOne}
        viewOp={viewOp}
        onCloseView={onCloseView}
        name="Opportunity"
      />
    </div>
  );
};

export default Opportunity;
