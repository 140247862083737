import React from 'react'

const AuthScreen = ({children}) => {
  return (
    <div className='auth_page'>
      {children}
    </div>
  )
}

export default AuthScreen
