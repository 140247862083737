import React from 'react'

// router 
import { BrowserRouter, Route, Routes } from 'react-router-dom'

// pages
import PrivateRoute from './routes/PrivateRoute';
import Layout from '../pages/layout';
import { Login } from '../pages/auth';

const EntryRoute = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" exact={true} element={<Login />} />
        <Route path="/*" element={<PrivateRoute><Layout /></PrivateRoute>} />
      </Routes>
    </BrowserRouter>
  )
}

export default EntryRoute
