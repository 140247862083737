import { Button, Empty, Modal, Spin } from "antd";
/* eslint-disable camelcase */
import React from "react";
// import { formatCurrency } from 'services/axios'
// import store from 'store'
// import * as FileSaver from 'file-saver';
// import * as XLSX from 'xlsx';
// import DataDisplay from '../shared/dataDisplay'
import ActionButton from "./actionButton";
import DataDisplay from "./dataDisplay";
import FileCard from "./fileCard";

// const userData = store.get("zero-user")

function FileLog({ loading, data, company, profile }) {
  const [drawer, setDrawer] = React.useState(false);
  const [opFile, setFile] = React.useState(false);
  const [fileDis, setFileDis] = React.useState({});
  const openFile = (d) => {
    setFile(true);
    setFileDis(d);
  };
  const closeFile = () => {
    setFile(false);
  };
  return (
    <div className="w-100">
      <ActionButton
        title={
          profile === "company" ? "Company Files" : `${profile.name} Files`
        }
        handle={() => setDrawer(!drawer)}
        img="/statement.png"
        visible
      />
      <Modal
        title={
          profile === "company"
            ? `${company?.company_name}`
            : `${profile.name} Documents Shared`
        }
        onCancel={() => setDrawer(!drawer)}
        visible={drawer}
        footer={false}
        width="100vw"
        bodyStyle={{ minHeight: "100vh" }}
        style={{
          top: 0,
        }}
        className="position-relative"
      >
        <div className="row">
          <div className="col-12 col-lg-5 mb-3">
            {profile !== "company" && (
              <DataDisplay
                className="py-1"
                border
                title={<b>NAMES</b>}
                detail={profile?.name || ""}
                align="start"
              />
            )}
            <DataDisplay
              className="py-1"
              border
              title={<b>Company Name</b>}
              detail={company && company?.company_name}
              align="start"
            />
            <DataDisplay
              className="py-1"
              border
              title={<b>Company RC NO</b>}
              detail={company && company.rc_number}
              align="start"
            />
            {/* <DataDisplay className="py-1" border title={<b>PAY UNIT</b>} detail="LIRS" align="start" /> */}
          </div>
          <Spin spinning={loading}>
            <div className="bg-transparent">
              <div className="border border-start-0 border-end-0">
                <div className="cui__utils__heading mb-0 p-3 border-bottom">
                  <strong>Documents</strong>
                </div>
                {data && data?.data?.length === 0 ? (
                  <div className="m-auto-0">
                    <Empty />
                  </div>
                ) : (
                  <div className="kit__utils__table bg-white">
                    {profile === "company" ? (
                      <div className="row">
                        {data &&
                          data?.data?.map((d, index) => (
                            <div className="col-md-4" key={d.id || index}>
                              <FileCard
                                file={d.path}
                                dat={d}
                                name={d.name}
                                status={d.status}
                                opFile={opFile}
                                isDate={d.issuing_date}
                                body={d.issuing_body}
                                date={d.expiring_date}
                                openFile={openFile}
                              />
                            </div>
                          ))}
                      </div>
                    ) : (
                      <div className="row">
                        {data &&
                          data?.data?.map((d, index) => (
                            <div className="col-md-4" key={d.id || index}>
                              <FileCard
                                file={d.path}
                                name={d.name}
                                status={d.status}
                                opFile={opFile}
                                openFile={openFile}
                                isDate={d.issuing_date}
                                body={d.issuing_body}
                                date={d.expiring_date}
                              />
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </Spin>
        </div>
        <div
          className="position-absolute bottom-0 end-0 p-5"
          id="print_display_none"
        >
          <Button onClick={() => setDrawer(false)} type="primary" size="large">
            close
          </Button>
        </div>
      </Modal>

      <Modal
        title={
          <>
            <h5>
              {profile === "company"
                ? `Company Name: ${company?.company_name}`
                : `Name: ${profile.name} Documents Shared`}
            </h5>
            <h6>Document Name: {fileDis.name}</h6>
          </>
        }
        onCancel={closeFile}
        visible={opFile}
        footer={false}
        width="100vw"
        bodyStyle={{ minHeight: "100vh" }}
        style={{
          top: 0,
        }}
        className="position-relative"
      >
        <iframe title="ppt" className="w-100 h-100" src={fileDis.path} />
      </Modal>
    </div>
  );
}

export default FileLog;
