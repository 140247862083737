import React from 'react'

// components
import { Layout, Menu } from 'antd';
import { FaBuilding, FaMapMarker, FaLightbulb, FaRunning, FaChalkboardTeacher } from "react-icons/fa";
// import {UserOutlined} from '@ant-design/icons';

// assets and constants
import logo from '../../../assets/images/logo/logo.jpeg';
import { Link, useLocation } from 'react-router-dom';

// body
const { Sider } = Layout;

const SideNav = () => {
  const location = useLocation();
  return (
    <Sider style={{
      overflow: 'auto',
      height: '100vh',
      position: 'fixed',
      left: 0,
      top: 0,
      bottom: 0}}
    >
      <div className="logo__container">
        <Link to="/">
          <img src={logo} alt='logo' className='logo-image' />
        </Link>
      </div>
      <Menu theme="dark" mode="inline" defaultSelectedKeys={[location.pathname]} >
        <Menu.Item key="/" icon={<FaChalkboardTeacher />}>
          <Link to="/">
            Dashboard
          </Link>
        </Menu.Item>
        <Menu.Item key="/opportunity" icon={<FaLightbulb />}>
          <Link to="/opportunity">
            Opportunity
          </Link>
        </Menu.Item>
        <Menu.Item key="/training" icon={<FaRunning />}>
          <Link to="/training">
            Training
          </Link>
        </Menu.Item>
        <Menu.Item key="/blog" icon={<FaMapMarker />}>
          <Link to="/blog">
            Blog
          </Link>
        </Menu.Item>
        {/* <Menu.Item key="/users" icon={<UserOutlined />}>
          <Link to="/users">
            User
          </Link>
        </Menu.Item> */}
        {/* <Menu.Item key="/admin" icon={<UserOutlined />}>
          <Link to="/admin">
            Admin
          </Link>
        </Menu.Item> */}
        <Menu.Item key="/company" icon={<FaBuilding />}>
          <Link to="/company">
            Company
          </Link>
        </Menu.Item>
        <Menu.Item key="/services" icon={<FaBuilding />}>
          <Link to="/services">
            Services
          </Link>
        </Menu.Item>
        <Menu.Item key="/request" icon={<FaBuilding />}>
          <Link to="/request">
            Service Request
          </Link>
        </Menu.Item>
        <Menu.Item key="/countries" icon={<FaMapMarker />}>
          <Link to="/countries">
            Countries
          </Link>
        </Menu.Item>
      </Menu>
    </Sider>
  )
}

export default SideNav
