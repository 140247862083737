import axiosInstance from "../../utility/axiosInstance";

// state
import { openNotification } from "../../modules";
import { setLoading, disableLoading } from "../../store/slices/loading";
import {
  disableServiceLoading,
  setServiceLoading,
  setService,
} from "../../store/slices/services";
import fetchApi from "../../utility/fetchApi";

export const getAllService = async (dispatch, navigate) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance(navigate).get("/get-services"); // show-file-logs
    if (response.data) {
      const { data } = response.data;
      dispatch(setService(data));
    }
  } catch (err) {
    const { data } = err.response;
    openNotification({
      type: "error",
      title: "Incorrect Credentials",
      description: data.message,
    });
  }
  dispatch(disableLoading());
};

export const createService = async (formData, dispatch, navigate) => {
  dispatch(setServiceLoading());
  try {
    const response = await axiosInstance(navigate).post(
      "/create-services",
      formData
    );
    if (response.data) {
      openNotification({
        type: "success",
        title: "Successful!",
        description: response.data.message,
      });
    }
    setTimeout(() => {
      window.location.reload();
    }, 500);
  } catch (err) {
    const { data } = err.response;
    openNotification({
      type: "error",
      title: "Incorrect Credentials",
      description: data.message,
    });
  }
  dispatch(disableServiceLoading());
};

export const editService = async (data, dispatch, navigate) => {
  dispatch(setServiceLoading());
  try {
    const response = await axiosInstance(navigate).put(`/edit-service`, data);
    if (response.data) {
      openNotification({
        type: "success",
        title: "Successful!",
        description: response.data.message,
      });
    }
    setTimeout(() => {
      window.location.reload();
    }, 500);
  } catch (err) {
    const { data } = err.response;
    openNotification({
      type: "error",
      title: "Incorrect Credentials",
      description: data.message,
    });
  }
  dispatch(disableServiceLoading());
};

export const delete_service = async (dispatch, id) => {
  dispatch(setServiceLoading());
  try {
    const response = await fetchApi.get(`/delete-service/${id}`);

    console.log(response.data);
    // if (response.data) {
    //   openNotification({
    //     type: "success",
    //     title: "Successful!",
    //     description: response.data.message,
    //   });
    // }
  } catch (err) {
    console.log(err?.response?.data);
    // const { data } = err.response;
    // openNotification({
    //   type: "error",
    //   title: "Incorrect Credentials",
    //   description: data.message,
    // });
  }
  dispatch(disableServiceLoading());
};
