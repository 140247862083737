import React from "react";

// router
import { useNavigate } from "react-router-dom";

// state
import { useDispatch } from "react-redux";
import {
  getAllOpportunity,
  getOpportunityTypes,
} from "../../../services/apis/opportunity";
import { getState } from "../../../services/apis/country";

const useCreateOpportunity = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = React.useState({
    opportunity_name: "",
    opportunity_file: "",
    opportunity_description: "",
    expire_date: "",
    opportunity_image: "",
    opportunity_type: "",
  });

  const [error, setError] = React.useState({
    opportunity_name: "",
    opportunity_file: "",
    opportunity_description: "",
    expire_date: "",
    opportunity_image: "",
  });

  const onChangeText = (text) => {
    const { value, name } = text.target;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const onValidate = () => {
    let err;
    if (!data.opportunity_name) {
      err = true;
    }
    if (!data.opportunity_description) {
      err = true;
    }
    if (!data.opportunity_image) {
      err = true;
    }
    if (err) {
      return false;
    }
    return true;
  };

  const getStateOpportunity = (id) => {
    getState(id, dispatch, navigate);
  };

  const onLoad = React.useCallback(() => {
    getAllOpportunity(dispatch, navigate);
  }, [dispatch, navigate]);

  const onGetCountry = React.useCallback(() => {
    getAllOpportunity(dispatch, navigate);
  }, [dispatch, navigate]);

  const onGetOpportunityType = React.useCallback(() => {
    getOpportunityTypes(dispatch, navigate);
  }, [dispatch, navigate]);

  return {
    data,
    error,
    onLoad,
    setData,
    setError,
    onValidate,
    onChangeText,
    onGetCountry,
    getStateOpportunity,
    onGetOpportunityType,
  };
};

export default useCreateOpportunity;
