import React from "react";

// components

// constants
import { BsPlus } from "react-icons/bs";

const TextMultipleInput = ({ container, onChange }) => {
  const [data, dataSet] = React.useState("");

  return (
    <div
      className={`w-full flex border rounded-lg overflow-hidden px-2 ${container}`}
    >
      <input
        type="text"
        name="multi_text"
        value={data}
        className="outline-none flex-1"
        onChange={(e) => {
          const { value } = e.target;
          dataSet(value);
        }}
      />
      <BsPlus
        size={30}
        className="cursor-pointer"
        onClick={() => {
          if (data) {
            dataSet("");
            onChange?.(data);
          }
        }}
      />
    </div>
  );
};

export default TextMultipleInput;
