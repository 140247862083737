import React from "react";

const TableHeader = ({ container, children }) => {
  return (
    <div
      className={`border-t-0 border-r-0 border-l-0 border-b-2 w-full my-3 text-[15px] font-bold flex justify-between items-center py-2 px-1' ${container}`}
    >
      {children}
    </div>
  );
};

export default TableHeader;
