import React from "react";

// request
import axiosUpload from "../../utility/axiosUpload";

// react router dom
import { useNavigate } from "react-router-dom";

const useUploadFiles = () => {
  const navigate = useNavigate();
  const [loading, loadingSet] = React.useState(false);

  const uploadFile = async (file) => {
    loadingSet(true);
    let path;
    try {
      const formData = new FormData();
      formData.append("file", file, { type: file.type });

      const config = {
        transformRequest: (formData) => formData,
      };
      const response = await axiosUpload(navigate).post(
        "/upload-file",
        formData,
        config
      );
      path = response.data;
    } catch (error) {
      path = null;
    }
    loadingSet(false);
    return path;
  };

  const uploadAvatar = async (file) => {
    loadingSet(true);
    let path;
    try {
      const formData = new FormData();
      formData.append("file", file, { type: file.type });

      const config = {
        transformRequest: (formData) => formData,
      };
      const response = await axiosUpload(navigate).post(
        "/upload",
        formData,
        config
      );
      path = response.data;
    } catch (error) {
      path = null;
    }
    loadingSet(false);
    return path;
  };

  return { uploadFile, loading, uploadAvatar };
};

export default useUploadFiles;
