import React, { useState } from 'react'
import useCreateCompaniesFile from '../hooks/useCreateCompaniesFile';

// components
import { Input, Modal, Form, Button, DatePicker, Image, Tag } from 'antd';
import moment from 'moment';
// state
import { useDispatch } from 'react-redux';
// import { createCompany } from '../../../services/apis/company';
import { useNavigate } from 'react-router-dom';
import { createCompaniesFile, editCompaniesFile } from '../../../services/apis/companiesFile';
import useUpload from '../../../services/hooks/useUpload';
// import { CloseOutlined, DownloadOutlined, EyeOutlined } from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';

const CreateCompaniesFile = ({visible, onHandler}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const opportunity = useSelector(({opportunity}) => opportunity)
  // const companies = useSelector(({company}) => company.companies);
  // const loading = useSelector(({company}) => company.loading)
  // const states = useSelector(({country}) => country.states?.states)
  // const countries = useSelector(({country}) => country.all_countries)
  const {data, onLoad, onGetCountry, date, onChange, onChangeText, onValidate} = useCreateCompaniesFile();
  const {uploadFile} = useUpload();
  const [loading, setLoading] = useState(false)
  // const [uploadType, setUploadType] = useState("")
  const [uplodImage, setUploadImage] = useState("")
  const handleOk = () => {
    const validate = onValidate();
    if (!validate) {
      return;
    }
    const formData = {...data, image: uplodImage, expire_date: date};
    console.log(formData)
    createCompaniesFile({...data, ...formData}, dispatch, navigate)
    onHandler?.()
  };

  React.useEffect(() => {
    onLoad();
    onGetCountry();
  }, [onLoad, onGetCountry]);

  return (
    <Modal
        title="Add CompaniesFile"
        open={visible}
        onOk={handleOk}
        confirmLoading={loading}
        onCancel={onHandler}
      >
        <Form layout="vertical" className="w-100">
          <div className="row">
            <div style={{margin: '0'}}>
              <Form.Item label='CompaniesFile Title'>
                <Input 
                  placeholder="CompaniesFile Title" 
                  value={data.name} 
                  name="name"
                  onChange={onChangeText} 
                />
              </Form.Item>
            </div>
            <div style={{margin: '0'}}>
            <Form.Item label='CompaniesFile body'>
              <TextArea 
                placeholder="CompaniesFile body" 
                value={data.body}
                rows={4}
                name="body"
                onChange={onChangeText} 
              />
            </Form.Item>
            </div>
            <div style={{margin: '0'}}>
              <Form.Item label='CompaniesFile Address'>
                <Input 
                  placeholder="CompaniesFile Address" 
                  value={data.address}
                  name="address"
                  onChange={onChangeText} 
                />
              </Form.Item>
            </div>
            <div className='col-md-6' style={{margin: '0'}}>
              <Form.Item label='CompaniesFile Link'>
                <Input 
                  placeholder="CompaniesFile Link" 
                  value={data.link}
                  name="link"
                  onChange={onChangeText} 
                />
              </Form.Item>
            </div>
            <div className='col-md-6' style={{margin: '0'}}>
              <Form.Item label='CompaniesFile Amount'>
                <Input 
                  placeholder="CompaniesFile Amount" 
                  value={data.amount}
                  name="amount"
                  onChange={onChangeText} 
                />
              </Form.Item>
            </div>
            <div className='col-md-6' style={{margin: '0'}}>
              <Form.Item label='CompaniesFile Duration'>
                <Input 
                  placeholder="CompaniesFile Duration" 
                  value={data.duration}
                  name="duration"
                  onChange={onChangeText} 
                />
              </Form.Item>
            </div>
            <div className='col-md-6' style={{margin: '0'}}>
              <Form.Item label='Select Expire Date'>
                <DatePicker
                  onChange={onChange}
                  format="YYYY-MM-DD HH:mm:ss"
                  showTime={{
                    defaultValue: moment('00:00:00', 'HH:mm:ss'),
                  }}
                />
              </Form.Item>
            </div>
            {/* <div style={{margin: '0'}}>              
              <Form.Item required label="Upload File">
                <Input 
                  type="file" 
                  placeholder="file" 
                  onChange={({target: {files}})=> {const file = files[0]; uploadFile({file, setFile: setUploadType, setLoading, dispatch} )}} 
                />
              </Form.Item>
            </div> */}
            <div style={{margin: '0'}}>              
              <Form.Item required label="Upload Image">
                <Input 
                  type="file" 
                  placeholder="Image" 
                  onChange={({target: {files}})=> {const file = files[0]; uploadFile({file, setFile: setUploadImage, setLoading, dispatch} )}} 
                />
              </Form.Item>
            </div>
          </div>
        </Form>
      </Modal>
  )
}

export default CreateCompaniesFile

export const EditModal = ({name, edit, setEdit, onCloseEdit, viewEdit}) => {
  const {uploadFile} = useUpload();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  // const [uploadType, setUploadType] = useState("")
  const [uplodImage, setUploadImage] = useState("")
  const [date, setDate] = useState("")
  const onChange = (date, dateString) => {
    setDate(dateString)
  }
  const onFinish = (data) => {
    const formData = {
      ...data, 
      image: uplodImage !== "" ? uplodImage : data.opportunity_image, 
      // opportunity_file: uploadType !== "" ? uploadType : data.opportunity_file, 
      expire_date: date !== "" ? date : data.expire_date
    };
    editCompaniesFile({...data, ...formData}, dispatch, navigate)
    console.log(formData)
  }
  return (
    <div>
      <Modal      
        title={`Edit ${name}`}
        visible={viewEdit} 
        onCancel={onCloseEdit} 
        footer={false}
      >
        <div>
          <Form layout="vertical" className="w-100" onFinish={()=>onFinish(edit)}>
            <div className="row">
              <div style={{margin: '0'}}>
                <Form.Item label='Edit CompaniesFile Title'>
                  <Input 
                    required 
                    placeholder="Enter CompaniesFile Title" 
                    value={edit.name} onChange={({target:{value}})=>setEdit({...edit, name: value})} 
                  />
                </Form.Item>
              </div>
              <div style={{margin: '0'}}>
              <Form.Item label='Edit CompaniesFile Description'>
                <TextArea 
                  required 
                  rows={4}
                  placeholder="Enter CompaniesFile Description" 
                  value={edit.body} 
                  onChange={({target:{value}})=>setEdit({...edit, body: value})} 
                />
              </Form.Item>
              </div>
              <div style={{margin: '0'}}>
              <Form.Item label='Edit CompaniesFile Address'>
                <Input 
                  required 
                  placeholder="Enter CompaniesFile Address" 
                  value={edit.address} 
                  onChange={({target:{value}})=>setEdit({...edit, address: value})} 
                />
              </Form.Item>
              </div>
              <div className='col-md-6' style={{margin: '0'}}>
              <Form.Item label='Edit CompaniesFile Amount'>
                <Input 
                  required 
                  placeholder="Enter CompaniesFile Amount" 
                  value={edit.amount} 
                  onChange={({target:{value}})=>setEdit({...edit, amount: value})} 
                />
              </Form.Item>
              </div>
              <div className='col-md-6' style={{margin: '0'}}>
              <Form.Item label='Edit CompaniesFile Link'>
                <Input 
                  required 
                  placeholder="Enter CompaniesFile Link" 
                  value={edit.link} 
                  onChange={({target:{value}})=>setEdit({...edit, link: value})} 
                />
              </Form.Item>
              </div>
              <div className='col-md-6' style={{margin: '0'}}>
              <Form.Item label='Edit CompaniesFile Duration'>
                <Input 
                  required 
                  placeholder="Enter CompaniesFile Duration" 
                  value={edit.duration} 
                  onChange={({target:{value}})=>setEdit({...edit, duration: value})} 
                />
              </Form.Item>
              </div>
              <div className='col-md-6' style={{margin: '0'}}>
                <Form.Item label='Select CompaniesFile Date'>
                  <DatePicker
                    // value={edit.expire_date} 
                    onChange={onChange}
                    format="YYYY-MM-DD HH:mm:ss"
                    defaultValue={moment(edit.date)}
                    showTime={{
                      defaultValue: moment(edit.date),
                    }}
                  />
                </Form.Item>
              </div>
              {/* <div style={{margin: '0'}}>              
                <Form.Item required label="Upload File">
                  <Input 
                    type="file" 
                    placeholder="file" 
                    // value={edit.opportunity_file} 
                    onChange={({target: {files}})=> {const file = files[0]; uploadFile({file, setFile: setUploadType, setLoading, dispatch} )}} 
                  />
                </Form.Item>
              </div> */}
              <div style={{margin: '0'}}>              
                <Form.Item required label="Upload Image">
                  <Input 
                    type="file" 
                    // value={edit.opportunity_image} 
                    placeholder="Image" 
                    onChange={({target: {files}})=> {const file = files[0]; uploadFile({file, setFile: setUploadImage, setLoading, dispatch} )}} 
                  />
                </Form.Item>
              </div>
              <div style={{margin: '0'}}>              
                <Form.Item>
                  <Button
                    htmlType="submit"
                    loading={loading}
                    size="large"
                  >
                    Update
                  </Button>
                </Form.Item>
              </div>
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  )
}

export const ViewModal = ({name, onCloseView, viewOp, viewOne}) => {
  return (
    <div>
      <Modal      
        title={`View ${name}`}
        visible={viewOne} 
        onCancel={onCloseView} 
        footer={false}
      >
        <div className='row'>
          <div>
            <Image
              width={200}
              src={viewOp?.image}
            />
            <h4>Title: {viewOp?.name}</h4>
            <p>Description: {viewOp?.body}</p>
            <p>Type: {viewOp?.address}</p>
            <p><Tag color="success">Active</Tag></p>  
          </div>
        </div>
      </Modal>
    </div>
  )
}