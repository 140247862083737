import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  companies: [],
  search: [],
  companyEmp: [],
  companyDoc: [],
  loading: false,
  company: null,
  last_page: null,
  current_page: null,
}

export const company = createSlice({
  name: 'company',
  initialState,
  reducers: {
    setCompanies: (state, action) => {
      state.companies = action.payload.data;
      state.last_page = action.payload.last_page
      state.current_page = action.payload.current_page
  },
    setCompany: (state, action) => {
        state.companies = action.payload;
    },
    setCompanyDoc: (state, action) => {
        state.companyDoc = action.payload;
    },
    setCompanyEmp: (state, action) => {
        state.companyEmp = action.payload;
    },
    setSearch: (state, action) => {
        state.companies = action.payload.data;
    },
    setCompanyLoading: (state) => {
        state.loading = true;
    },
    disableCompanyLoading: (state) => {
        state.loading = false;
    }
  },
})

// Action creators are generated for each case reducer function
export const { setSearch, setCompanyDoc, setCompanyEmp, setCompany, setCompanyLoading, disableCompanyLoading, setCompanies } = company.actions

export default company.reducer