import React from "react";
import useDashboard from "../hooks/useDashboard";

// state
import { useDispatch, useSelector } from "react-redux";

// components
import CreateService, { ViewModal, EditModal } from "../misc/CreateServices";
import { Table, Tag, Tooltip, Button } from "antd";

// constants and icon
import { FaRegBuilding } from "react-icons/fa";
import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import { delete_service } from "../../../services/apis/services";

const Services = () => {
  const services = useSelector(({ service }) => service?.services);
  const loading = useSelector(({ loading }) => loading.loading);
  const dispatch = useDispatch();
  const {
    edit,
    viewOp,
    viewOne,
    visible,
    viewEdit,
    onLoad,
    editOpen,
    viewOpen,
    setEdit,
    onCloseView,
    onCloseEdit,
    setVisible,
    onCloseModal,
  } = useDashboard();

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (e) => (
        <p
          className="bg-light position text-dark text-truncate"
          style={{ maxWidth: "150px" }}
        >
          <Tooltip placement="topLeft" title={e}>
            {e}
          </Tooltip>
        </p>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (e) => (
        <Tag color={`${e === "1" ? "success" : "error"}`}>
          {e === "1" ? "Active" : "Inactive"}
        </Tag>
      ),
    },
    {
      title: "Action",
      key: "id",
      dataIndex: "id",
      render: (id, data) => (
        <>
          <Tooltip title="View Service">
            <Button
              type="primary"
              shape="circle"
              icon={<EyeOutlined />}
              onClick={() => viewOpen(data)}
            />
          </Tooltip>
          <Tooltip title="Edit Service">
            <Button
              className="bg-success text-white"
              shape="circle"
              icon={<EditOutlined />}
              onClick={() => editOpen(data)}
            />
          </Tooltip>
          <Tooltip title="Delete Service">
            <Button
              className="bg-danger text-white"
              shape="circle"
              icon={<DeleteOutlined />}
              onClick={() => delete_service(dispatch, id)}
            />
          </Tooltip>
        </>
      ),
    },
  ];

  return (
    <div style={{ flex: 1 }}>
      {/* <h2>Service Page</h2> */}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <Button type="primary" size="large" onClick={() => setVisible(true)}>
            <div className="flex justify-center items-center w-full">
              <FaRegBuilding className="mr-1" /> Create
            </div>
          </Button>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          marginTop: 20,
        }}
        className="kit__utils__table"
      >
        <Table
          columns={columns}
          rowKey="id"
          dataSource={services}
          loading={loading}
          className="mb-2"
          pagination={false}
        />
      </div>
      <CreateService visible={visible} onHandler={onCloseModal} />
      <EditModal
        edit={edit}
        setEdit={setEdit}
        viewEdit={viewEdit}
        onCloseEdit={onCloseEdit}
        name="Service"
      />
      <ViewModal
        viewOne={viewOne}
        viewOp={viewOp}
        onCloseView={onCloseView}
        name="Service"
      />
    </div>
  );
};

export default Services;
