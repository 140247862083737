import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  users: [],
  docFile: [],  
  fileLogs: [],
  last_page: null,
  user:{},
  current_page: null,
  loading: false,
}

export const opportunity = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUsers: (state, action) => {
      state.users = action.payload.data;
      state.last_page = action.payload.last_page
      state.current_page = action.payload.current_page
  },
    setUser: (state, action) => {
          state.user = action.payload;
      },
    setUserFileLog: (state, action) => {
        state.fileLogs = action.payload;
      },
    setUserDocFile: (state, action) => {
          state.docFile = action.payload;
      },
    setSearch: (state, action) => {
        state.users = action.payload.data;
    },
    setUserLoading: (state) => {
        state.loading = true;
    },
    disableUserLoading: (state) => {
        state.loading = false;
    }
  },
})

// Action creators are generated for each case reducer function
export const { setSearch, setUpload, setUser, setUserLoading, setUserDocFile, setUserFileLog, disableUserLoading, setUsers } = opportunity.actions

export default opportunity.reducer