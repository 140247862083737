import React from "react";

// components
import { Spin } from "antd";

const TextInput = ({
  label,
  name,
  value,
  type,
  placeholder,
  loading,
  icon,
  onChange,
}) => {
  return (
    <div className="w-full">
      {label && <div className="w-full block mb-[2px] font-bold">{label}</div>}
      <div className="flex w-full py-[8px] rounded-sm border hover:border-[#67C748]">
        {icon && (
          <div className="border-r w-[40px] flex justify-center items-center">
            {icon}
          </div>
        )}
        <div className="flex-1 px-[6px]">
          <input
            name={name}
            value={value}
            type={type || "text"}
            placeholder={placeholder}
            onChange={onChange}
            className="w-full border-none outline-none focus:outline-none placeholder:text-[15px]"
          />
        </div>
        {loading && (
          <div className="w-[5%] flex justify-center items-center">
            <Spin size="small" />
          </div>
        )}
      </div>
    </div>
  );
};

export default TextInput;
