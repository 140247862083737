import React from 'react'

// router
import { useNavigate } from 'react-router-dom';

// state
import { useDispatch } from 'react-redux';
import { getPaginatedCompany } from '../../../services/apis/company';

const useDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = React.useState(1);
  const [visible, setVisible] = React.useState(false);

  const onChange = (date, dateString) => {
    console.log(date, dateString)
  }

  const onSearch = (value) => console.log(value);

  const onCloseModal = () => {
    setVisible(false);
  }

  const onLoad = React.useCallback(() => {
    getPaginatedCompany(dispatch, navigate, page);
  }, [dispatch, navigate, page]);

  return {onLoad, setPage, visible, setVisible, onCloseModal, onSearch, onChange, page}
}

export default useDashboard
