import React, { useState } from "react";
import useCreateTraining from "../hooks/useCreateTraining";

// components
import {
  Input,
  Select,
  Modal,
  Form,
  Button,
  DatePicker,
  Image,
  Tag,
} from "antd";
import moment from "moment";
// state
import { useDispatch, useSelector } from "react-redux";
// import { createCompany } from '../../../services/apis/company';
import { useNavigate } from "react-router-dom";
import { createTraining, editTraining } from "../../../services/apis/training";
import useUploadFiles from "../../../services/hooks/useUploadFiles";
// import { CloseOutlined, DownloadOutlined, EyeOutlined } from '@ant-design/icons';
import TextArea from "antd/lib/input/TextArea";
import Upload from "../../../component/modals/upload";

const CreateTraining = ({ visible, onHandler }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector(({ training }) => training.loading);
  const states = useSelector(({ country }) => country.states?.states);
  const countries = useSelector(({ country }) => country.all_countries);
  const {
    data,
    error,
    onLoad,
    setData,
    setError,
    onValidate,
    onChangeText,
    onGetCountry,
    getStateCountry,
  } = useCreateTraining();
  const { uploadAvatar } = useUploadFiles();
  const [xloading] = useState(false);
  const handleOk = () => {
    const validate = onValidate();
    console.log(data, validate, error);
    if (!validate) {
      return;
    }
    // const formData = { ...data, image: uplodImage, date: date };
    // console.log(formData);
    createTraining({ ...data }, dispatch, navigate);
    onHandler?.();
  };
  React.useEffect(() => {
    onLoad();
    onGetCountry();
  }, [onLoad, onGetCountry]);
  return (
    <Modal
      title="Add Training"
      open={visible}
      onOk={handleOk}
      confirmLoading={loading || xloading}
      onCancel={onHandler}
    >
      <Form layout="vertical" className="w-100">
        <div className="row">
          <div style={{ margin: "0" }}>
            <Form.Item label="Training Title">
              <Input
                placeholder="Training Title"
                value={data.name}
                name="name"
                onChange={onChangeText}
              />
            </Form.Item>
          </div>
          <div style={{ margin: "0" }}>
            <Form.Item label="Training body">
              <TextArea
                placeholder="Training body"
                value={data.body}
                rows={4}
                name="body"
                onChange={onChangeText}
              />
            </Form.Item>
          </div>
          <div style={{ margin: "0" }}>
            <Form.Item label="Training Address">
              <Input
                placeholder="Training Address"
                value={data.address}
                name="address"
                onChange={onChangeText}
              />
            </Form.Item>
          </div>
          <div className="col-md-6" style={{ margin: "0" }}>
            <Form.Item label="Training Link">
              <Input
                placeholder="Training Link"
                value={data.link}
                name="link"
                onChange={onChangeText}
              />
            </Form.Item>
          </div>
          <div className="col-md-6" style={{ margin: "0" }}>
            <Form.Item label="Training Amount">
              <Input
                placeholder="Training Amount"
                value={data.amount}
                name="amount"
                onChange={onChangeText}
              />
            </Form.Item>
          </div>
          <div className="col-md-6" style={{ margin: "0" }}>
            <Form.Item label="Training Duration">
              <Input
                placeholder="Training Duration"
                value={data.duration}
                name="duration"
                onChange={onChangeText}
              />
            </Form.Item>
          </div>
          <div className="col-md-6" style={{ margin: "0" }}>
            <Form.Item label="Select Expire Date">
              <DatePicker
                onChange={(_date, dateString) => {
                  setData((prev) => ({ ...prev, date: dateString }));
                  setError((prev) => ({ ...prev, date: "" }));
                }}
                format="YYYY-MM-DD HH:mm:ss"
                showTime={{
                  defaultValue: moment("00:00:00", "HH:mm:ss"),
                }}
              />
            </Form.Item>
          </div>
          <div className="col-md-6" style={{ margin: "0" }}>
            <Form.Item label="Training Country">
              <Select
                defaultValue=""
                style={{ width: "100%" }}
                name="country_id"
                onChange={(text) => {
                  setData((prev) => ({ ...prev, country_id: text }));
                  getStateCountry(text);
                }}
              >
                <Select.Option value="">Select A Country</Select.Option>
                {countries.length > 0 &&
                  countries.map((item, index) => (
                    <Select.Option key={index} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </div>
          <div className="col-md-6" style={{ margin: "0" }}>
            <Form.Item label="Training State">
              <Select
                defaultValue=""
                style={{ width: "100%" }}
                name="state_id"
                onChange={(text) => {
                  setData((prev) => ({ ...prev, state_id: text }));
                  setError((prev) => ({ ...prev, state_id: "" }));
                }}
              >
                <Select.Option value="">Select State</Select.Option>
                {states?.length > 0 &&
                  states.map((item) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </div>
          <div style={{ margin: "0" }}>
            <Upload
              title="Upload Image"
              name="description"
              loading={loading}
              required={true}
              status={error.image}
              completed={data.image}
              onChange={async (res) => {
                setError((prev) => ({ ...prev, image: "" }));
                const response = await uploadAvatar(res);
                if (typeof response === "string") {
                  setData((prev) => ({ ...prev, image: response }));
                } else {
                  setError((prev) => ({ ...prev, image: true }));
                }
              }}
            />
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default CreateTraining;

export const EditModal = ({ name, edit, setEdit, onCloseEdit, viewEdit }) => {
  const { uploadFile } = useUploadFiles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [uplodImage, setUploadImage] = useState("");
  const [date, setDate] = useState("");
  const onChange = (date, dateString) => {
    setDate(dateString);
  };
  const onFinish = (data) => {
    const formData = {
      ...data,
      image: uplodImage !== "" ? uplodImage : data.image,
      date: date !== "" ? date : data.date,
    };
    editTraining({ ...data, ...formData }, dispatch, navigate);
  };
  return (
    <div>
      <Modal
        title={`Edit ${name}`}
        open={viewEdit}
        onCancel={onCloseEdit}
        footer={false}
      >
        <div>
          <Form
            layout="vertical"
            className="w-100"
            onFinish={() => onFinish(edit)}
          >
            <div className="row">
              <div style={{ margin: "0" }}>
                <Form.Item hidden>
                  <Input required hidden value={edit.id} />
                </Form.Item>
                <Form.Item label="Edit Training Title">
                  <Input
                    required
                    placeholder="Enter Training Title"
                    value={edit.name}
                    onChange={({ target: { value } }) =>
                      setEdit({ ...edit, name: value })
                    }
                  />
                </Form.Item>
              </div>
              <div style={{ margin: "0" }}>
                <Form.Item label="Edit Training Description">
                  <TextArea
                    required
                    rows={4}
                    placeholder="Enter Training Description"
                    value={edit.body}
                    onChange={({ target: { value } }) =>
                      setEdit({ ...edit, body: value })
                    }
                  />
                </Form.Item>
              </div>
              <div style={{ margin: "0" }}>
                <Form.Item label="Edit Training Address">
                  <Input
                    required
                    placeholder="Enter Training Address"
                    value={edit.address}
                    onChange={({ target: { value } }) =>
                      setEdit({ ...edit, address: value })
                    }
                  />
                </Form.Item>
              </div>
              <div className="col-md-6" style={{ margin: "0" }}>
                <Form.Item label="Edit Training Amount">
                  <Input
                    required
                    placeholder="Enter Training Amount"
                    value={edit.amount}
                    onChange={({ target: { value } }) =>
                      setEdit({ ...edit, amount: value })
                    }
                  />
                </Form.Item>
              </div>
              <div className="col-md-6" style={{ margin: "0" }}>
                <Form.Item label="Edit Training Link">
                  <Input
                    required
                    placeholder="Enter Training Link"
                    value={edit.link}
                    onChange={({ target: { value } }) =>
                      setEdit({ ...edit, link: value })
                    }
                  />
                </Form.Item>
              </div>
              <div className="col-md-6" style={{ margin: "0" }}>
                <Form.Item label="Edit Training Duration">
                  <Input
                    required
                    placeholder="Enter Training Duration"
                    value={edit.duration}
                    onChange={({ target: { value } }) =>
                      setEdit({ ...edit, duration: value })
                    }
                  />
                </Form.Item>
              </div>
              <div className="col-md-6" style={{ margin: "0" }}>
                <Form.Item label="Select Training Date">
                  <DatePicker
                    // value={edit.expire_date}
                    onChange={onChange}
                    format="YYYY-MM-DD HH:mm:ss"
                    defaultValue={moment(edit.date)}
                    showTime={{
                      defaultValue: moment(edit.date),
                    }}
                  />
                </Form.Item>
              </div>
              <div style={{ margin: "0" }}>
                <Form.Item required label="Upload Image">
                  <Input
                    type="file"
                    value={edit.opportunity_image}
                    placeholder="Image"
                    onChange={({ target: { files } }) => {
                      const file = files[0];
                      uploadFile({
                        file,
                        setFile: setUploadImage,
                        setLoading,
                        dispatch,
                      });
                    }}
                  />
                </Form.Item>
              </div>
              <div style={{ margin: "0" }}>
                <Form.Item>
                  <Button htmlType="submit" loading={loading} size="large">
                    Update
                  </Button>
                </Form.Item>
              </div>
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

export const ViewModal = ({ name, onCloseView, viewOp, viewOne }) => {
  return (
    <div>
      <Modal
        title={`View ${name}`}
        open={viewOne}
        onCancel={onCloseView}
        footer={false}
      >
        <div className="row">
          <div>
            <Image width={200} src={viewOp?.image} />
            <h4>Title: {viewOp?.name}</h4>
            <p>Description: {viewOp?.body}</p>
            <p>Address: {viewOp?.address}</p>
            <p>Date: {viewOp?.date}</p>
            <p>
              <Tag color="success">Active</Tag>
            </p>
          </div>
        </div>
      </Modal>
    </div>
  );
};
