import React from 'react';

// Components
import { Button, Tag } from 'antd';

// react router dom
import { Link, useNavigate } from 'react-router-dom';

// icons and constants
import { GoEye } from 'react-icons/go';

// state
import { useDispatch } from 'react-redux';
import {getUsers} from '../../../services/apis/user';

const useHome = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const [page, setPage] = React.useState(1);
  const [visible, setVisible] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [openD, setOpenD] = React.useState({});
  

  const columns = React.useRef([
    {
      title: 'Name',
      dataIndex: 'profile',
      key: 'name',
      render: (e, data) => (
        <p>{data.profile.name}</p>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      render: (e, data) => (
        <>{data.role === '0' ? <Tag>User</Tag> : <Tag>Company Admin</Tag>}</>
      ),
    },
    {
      title: 'ACTION',
      key: 'action',
      render: (da, item) => (
        <>
          <Link to= '/user/details' state= {item}>
            <Button style={{marginLeft: 2}} type="primary" icon={<GoEye />}>
              {' '}View
            </Button>
          </Link>
          {item.profile.company === null &&
            <Button style={{marginLeft: 2}} type="primary" onClick={()=> onOpen(item)}>
              Add to Company
            </Button>
          }
        </>
      ),
    },
  ]);

  const onCloseModal = () => {    
    setVisible(false);
  } 
  const onOpen = (d) => {
    setOpen(true)
    setOpenD(d)
  }

  const onCloseOpenModal = () => {    
    setOpen(false);
  }

  const onLoad = React.useCallback(() => {
    getUsers(dispatch, navigate, page)
  }, [dispatch, navigate, page]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);
  return {page, columns, setPage, openD, setOpenD, setOpen, onCloseOpenModal, visible, open, setVisible, onCloseModal}
}

export default useHome
