import axiosInstance from "../../utility/axiosInstance";

// state
import { setLoading, disableLoading } from "../../store/slices/loading";
import { openNotification } from "../../modules";
import { setAllCountry, setCountry, setState } from "../../store/slices/country";

export const getCountry = async (dispatch, navigate, page=1) => {
    dispatch(setLoading());
    try {
        const response = await axiosInstance(navigate).get(`/get-all-countries?page=${page}`);
        if (response.data) {
            dispatch(setCountry(response.data))
        }
    } catch (err) {
        console.log(err);
        const {data} = err.response;
        openNotification({type: 'error', title: 'Incorrect Credentials', description: data.message})
    }
    dispatch(disableLoading());
}

export const getState = async (id, dispatch, navigate) => {
    dispatch(setLoading());
    try {
        const response = await axiosInstance(navigate).get(`/get-states/${id}`);
        if (response.data) {
            dispatch(setState(response.data))
        }
    } catch (err) {
        const {data} = err.response;
        openNotification({type: 'error', title: 'Incorrect Credentials', description: data.message})
    }
    dispatch(disableLoading());
}

export const getAllCountry = async (dispatch, navigate) => {
    dispatch(setLoading());
    try {
        const response = await axiosInstance(navigate).get('/all-country');
        if (response.data) {
            dispatch(setAllCountry(response.data))
        }
    } catch (err) {
        const {data} = err.response;
        openNotification({type: 'error', title: 'Incorrect Credentials', description: data.message})
    }
    dispatch(disableLoading());
}
