import React from 'react'
// import useDashboard from '../hooks/useDashboard';
// state
import { useDispatch, useSelector } from 'react-redux';

// components
import { FaRegBuilding } from 'react-icons/fa';
import { Table, Tooltip, Button, Modal, Form, Input, Tag } from 'antd'

// constants and icon
import { ArrowLeftOutlined, DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { createBlogTag, editBlogTag, setRemoveBlogTag } from '../../../services/apis/blog';
import { Link, useNavigate } from 'react-router-dom';
import useCreateBlog from '../hooks/useCreateBlog';

const { confirm } = Modal
const BlogTags = () => {
    const blog = useSelector(({blog}) => blog)
    const { blogTags } = blog
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [visible, setVisible] = React.useState(false);
    const [viewEdit, setViewEdit] = React.useState(false);
    const [edit, setEdit] = React.useState({});

   const editOpen = (data) =>{
    setEdit(data)
    // console.log(data)
    setViewEdit(true)
  }
  
  const onCloseEdit = () =>{
    setViewEdit(false)
  }
  
  const onFinish = data =>{
    // console.log(data)
    createBlogTag({...data}, dispatch, navigate)
    // setVisible(false)
  }
  
  const onFinishEdit = data =>{
    // console.log(data)
    editBlogTag({...data}, dispatch, navigate)
    // setVisible(false)
  }

  const onHandler =()=>{
    setVisible(false)
  }
    const loading = useSelector(({loading}) => loading.loading);
//   const {onLoad} = useDashboard();
  const {onLoad, onGetBlogTags} = useCreateBlog();

  React.useEffect(() => {
    onLoad();
    onGetBlogTags()
  }, [onLoad, onGetBlogTags]);

  const delOpport = ({id, name }) => {
    const data = {
        id:''
    }
      confirm({
        title: `Are you sure you want to delete ${name} ?`,
        icon: <ExclamationCircleOutlined />,
        content: (<h4>{name}</h4>),
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk() {
          setRemoveBlogTag({...data, id }, dispatch, navigate)
        },
        onCancel() {
          console.log('Cancel')
        },
      })
    }
  

  const columns = [
    {
      title: 'Name',
      dataIndex: 'tag_name',
      key: 'tag_name',
      render: e => (
        <p className="bg-light position text-dark text-truncate" style={{ maxWidth: '150px' }}>
          <Tooltip placement="topLeft" title={e}>
            {e}
          </Tooltip>
        </p>
      )
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (e, data) => (
        <>{data?.status === '0' && <Tag color='green'>Active</Tag>}</>
      ),
    },
    {
      title: 'Action',
      key: 'id',
      dataIndex: 'id',
      render: (id, data) => (
        <>
          <Tooltip title="Edit Blog">
            <Button className='bg-success text-white' shape="circle" icon={<EditOutlined />} onClick={() => editOpen(data)} />
          </Tooltip>
          <Tooltip title="Delete Blog">
            <Button className='bg-danger text-white' shape="circle" icon={<DeleteOutlined />} onClick={() => delOpport(data)} />
          </Tooltip>
        </>
      ),
    },
  ];

  return (
    <div style={{flex: 1}}>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <div>
          <Button type="primary" icon={<FaRegBuilding />} size='large' onClick={() => setVisible(true)}>
            Create
          </Button>
        </div>
        <div>
          <Link to='/blog'>
            <Button type="primary" icon={<ArrowLeftOutlined />} size='large'>
              Back
            </Button>
          </Link>
        </div>
      </div>
      <div style={{display: 'flex', flex: 1, flexDirection: 'column', marginTop: 20}} className="kit__utils__table">
        <h2>Blog Tags</h2>
        <Table 
            columns={columns}
            rowKey="id"
            dataSource={blogTags}
            loading={loading}
            className="mb-2"
            pagination={false} 
        />
      </div>
      
    <Modal
      title="Add Blog Tags"
      open={visible}
      footer={false}
      // confirmLoading={loading}
      onCancel={onHandler}
    >
      <Form layout="vertical" onFinish={onFinish} > 
        <Form.Item label='Tags Name' name='name' rules={[{ required: true }]}>
          <Input 
              required 
              placeholder="Enter Name of the Blog Tags Name" 
          />
        </Form.Item>              
        <Form.Item>
          <Button
          htmlType="submit"
          loading={loading}
          size="large"
          >
          Create
          </Button>
        </Form.Item>
      </Form>
    </Modal>

    
    <Modal      
        title={`Edit ${edit.name}`}
        visible={viewEdit} 
        onCancel={onCloseEdit} 
        footer={false}
      >
        <div>
          <Form layout="vertical" className="w-100" onFinish={()=>onFinishEdit(edit)}>
            <div className="row">
              <div style={{margin: '15px 0'}}>
                <Form.Item label='Edit Tags Name'>
                  <Input 
                    required 
                    placeholder="Enter Tags" 
                    value={edit.name} 
                    onChange={({target:{value}})=>setEdit({...edit, name: value})} 
                  /> 
                </Form.Item>
              </div>
              <div style={{margin: '15px 0'}}>
              <Form.Item hidden label=''>
                <Input 
                  hidden
                  required 
                  placeholder="" 
                  value={edit.id} 
                />
              </Form.Item>
              </div>
              <div style={{margin: '15px 0'}}>              
                <Form.Item>
                  <Button
                    htmlType="submit"
                    loading={loading}
                    size="large"
                  >
                    Update
                  </Button>
                </Form.Item>
              </div>
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  );
}

export default BlogTags
