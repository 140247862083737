import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  countries: [],
  states: null,
  last_page: null,
  current_page: null,
  all_countries: [],
}

export const country = createSlice({
  name: 'country',
  initialState,
  reducers: {
    setCountry: (state, action) => {
        state.countries = action.payload.data;
        state.last_page = action.payload.last_page;
        state.current_page = action.payload.current_page;
    },
    setState: (state, action) => {
        state.states = action.payload;
    },
    setAllCountry: (state, action) => {
      state.all_countries = action.payload;
    }
  },
})

// Action creators are generated for each case reducer function
export const { setCountry, setState, setAllCountry } = country.actions

export default country.reducer