import { SmileOutlined, WarningOutlined } from '@ant-design/icons';
import { notification } from 'antd';
import React from 'react';

const openNotification = ({title, description, type}) => {
  notification.open({
    message: title.toUpperCase(),
    description: description,
    icon: ( type === 'success' ? 
      <SmileOutlined
        style={{
          color: '#108ee9',
        }}
      /> : <WarningOutlined style={{color: 'red'}} />
    ),
  });
};

export default openNotification;