import React from "react";

const Tab = ({ value }) => {
  return (
    <div className="px-[14px] py-[4px] bg-[#ADD8E6] mr-2 rounded-xl text-[blue]">
      {value}
    </div>
  );
};

export default Tab;
