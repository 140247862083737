import axiosInstance from "../../utility/axiosInstance";

// state
import { setLoading, disableLoading } from "../../store/slices/loading";
import { setAuth, setUser } from "../../store/slices/auth";
import { openNotification } from "../../modules";

export const authUser = async (data, dispatch, navigate) => {
    dispatch(setLoading());
    try {
        const response = await axiosInstance(navigate).post('/login', data);
        if (response.data) {
            localStorage.setItem('@serial', response?.data?.access_token)
            dispatch(setAuth(response.data))
            navigate('/');
        }
    } catch (err) {
        const {data} = err.response;
        openNotification({type: 'error', title: 'Incorrect Credentials', description: data.message})
    }
    dispatch(disableLoading());
}

export const getAuthUser = async (dispatch, navigate) => {
    dispatch(setLoading())
    try {
        const response = await axiosInstance(navigate).get('/user');
        dispatch(setUser(response.data))
    } catch (err) {
        const {data} = err.response;
        openNotification({type: 'error', title: 'Incorrect Credentials', description: data.message})
    }
    dispatch(disableLoading());
}