import React from "react";

// router
import { useNavigate } from "react-router-dom";

// state
import { useDispatch } from "react-redux";
import {
  getAllBlog,
  getBlogCats,
  getBlogTags,
} from "../../../services/apis/blog";
import { getState } from "../../../services/apis/country";

const useCreateBlog = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [name, setName] = React.useState("");
  // const [file, setFile] = React.useState('')
  const [image] = React.useState("");
  const [date, setDate] = React.useState("");
  const [data, setData] = React.useState({
    title: "",
    category_id: "",
    tags: "",
    position: "",
    body: "",
    blog_file: "",
    // blog_type: "",
  });

  const [error, setError] = React.useState({
    blog_name: "",
    blog_file: "",
    blog_description: "",
  });

  const onChangeText = (text) => {
    const { value, name } = text.target;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const onChange = (date, dateString) => {
    setDate(dateString);
  };

  const onValidate = () => {
    let err;
    if (!data.title) {
      err = true;
      setError((prev) => ({ ...prev, title: "Error" }));
    }
    if (!data.body) {
      err = true;
      setError((prev) => ({ ...prev, body: "Error" }));
    }
    if (!data.category_id) {
      err = true;
      setError((prev) => ({ ...prev, category_id: "Error" }));
    }
    if (err) {
      return false;
    }
    return true;
  };

  const onSetInput = ({ target }) => {
    const { name, value } = target;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const getStateBlog = (id) => {
    getState(id, dispatch, navigate);
  };

  const onLoad = React.useCallback(() => {
    getAllBlog(dispatch, navigate);
  }, [dispatch, navigate]);

  const onGetBlogTags = React.useCallback(() => {
    getBlogTags(dispatch, navigate);
  }, [dispatch, navigate]);

  const onGetBlogCats = React.useCallback(() => {
    getBlogCats(dispatch, navigate);
  }, [dispatch, navigate]);

  return {
    data,
    date,
    image,
    name,
    error,
    onLoad,
    setName,
    setData,
    setError,
    onChange,
    onValidate,
    onChangeText,
    getStateBlog,
    onGetBlogTags,
    onGetBlogCats,
    onSetInput,
  };
};

export default useCreateBlog;
