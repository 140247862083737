import React, { useState } from "react";
import useCreateBlog from "../hooks/useCreateBlog";
import useUploadFiles from "../../../services/hooks/useUploadFiles";

// components
import { Input, Select, Modal, Form, Button, Image, Tag } from "antd";
import Upload from "../../../component/modals/upload";

// state
import { useDispatch, useSelector } from "react-redux";
// import { createCompany } from '../../../services/apis/company';
import { useNavigate } from "react-router-dom";
import { editBlog } from "../../../services/apis/blog";
import useUpload from "../../../services/hooks/useUpload";
import { DownloadOutlined } from "@ant-design/icons";
import { Editor } from "react-draft-wysiwyg";
import { getHtml } from "../../../utility/wysUtility";

const CreateBlog = ({ visible, onHandler }) => {
  // const dispatch = useDispatch();
  // const navigate = useNavigate();
  const blog = useSelector(({ blog }) => blog);
  const { blogTags, blogCats, loading } = blog;
  const [mLoading] = useState(false);
  const { uploadAvatar, loading: imageLoader } = useUploadFiles();
  const { data, setData, error, setError, onGetBlogCats, onGetBlogTags } =
    useCreateBlog();
  const [editor, setEditor] = useState("");
  // const [uplodImage, setUploadImage] = useState("");
  const onEditorStateChange = (editorState) => {
    setEditor(editorState);
  };
  const handleOk = (e) => {
    // const validate = onValidate();
    // if (!validate) {
    //   return;
    // }
    console.log(e);
    // const formData = { ...e, image: uplodImage, body: getHtml(editor) };
    // const formData = {...data, body: getHtml(editor),  expire_date: date};
    // console.log(formData);
    // createBlog({ ...formData }, dispatch, navigate);
    // onHandler?.()
  };

  React.useEffect(() => {
    onGetBlogCats();
    onGetBlogTags();
  }, [onGetBlogCats, onGetBlogTags]);

  return (
    <Modal
      title="Add Blog"
      open={visible}
      footer={false}
      // onOk={handleOk}
      // confirmLoading={loading}
      onCancel={onHandler}
    >
      <Form layout="vertical" className="w-100" onFinish={handleOk}>
        <Form.Item label="Blog Title" name="title" rules={[{ required: true }]}>
          <Input
            required
            // name='title'
            // value={data.title}
            // onChange={onChangeText}
            // onChange={({target:{value}})=>setData({...data, title: value})}
            placeholder="Enter Blog Title"
          />
        </Form.Item>
        <Form.Item label="Blog Content" rules={[{ required: true }]}>
          <Editor
            editorState={editor}
            editorClassName="px-3 border border-gray-2"
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            // editorClassName="editorClassName"
            editorStyle={{
              height: 300,
              overflow: "auto",
            }}
            onEditorStateChange={onEditorStateChange}
          />
        </Form.Item>
        {/* <Form.Item required label="Upload Image">
          <Input
            type="file"
            // loading={mLoading}
            // value={edit.opportunity_image}
            placeholder="Image"
            onChange={({ target: { files } }) => {
              const file = files[0];
              uploadFile({
                file,
                setFile: setUploadImage,
                setLoading,
                dispatch,
              });
            }}
          />
        </Form.Item> */}
        <Form.Item
          label="Blog Category"
          name="category_id"
          rules={[{ required: true }]}
        >
          <Select
          // name='category_id'
          // value={data.category_id}
          // onChange={onChangeText}
          >
            {blogCats?.map((d) => (
              <Select.Option value={d.id} key={d.id}>
                {d.category_name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Blog Position" name="position">
          <Input
            type="number"
            // name='position'
            // value={data.position}
            // onChange={({target:{value}})=>setData({...data, position: value})}
          />
        </Form.Item>
        <Form.Item label="Blog Tags" name="tags">
          <Select
            mode="multiple"
            style={{
              width: "100%",
            }}
            placeholder="select blog tag"
            // onChange={(value)=>{console.log(setTags(value))}}
            optionLabelProp="label"
          >
            {blogTags?.map((e, i) => (
              <Select.Option key={i} value={e.id}>
                {e.tag_name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <div className="my-2">
          <Upload
            title="Upload Image"
            name="blog_file"
            loading={imageLoader}
            status={error.blog_file}
            completed={data.blog_file}
            onChange={async (res) => {
              setError((prev) => ({ ...prev, blog_file: "" }));
              const response = await uploadAvatar(res);
              if (typeof response === "string") {
                setData((prev) => ({ ...prev, blog_file: response }));
              } else {
                setError((prev) => ({ ...prev, blog_file: true }));
              }
            }}
          />
        </div>

        <Form.Item>
          <Button htmlType="submit" loading={loading || mLoading} size="large">
            submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateBlog;

export const EditModal = ({
  name,
  setEditor,
  editor,
  edit,
  onCloseEdit,
  viewEdit,
}) => {
  console.log("setEditor", name);
  const { uploadFile } = useUpload();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const blog = useSelector(({ blog }) => blog);
  const { blogTags, blogCats, loading } = blog;
  const [mLoading, setLoading] = useState(false);
  const [uplodImage, setUploadImage] = useState("");
  const { category, tags } = edit;
  const onEditorStateChange = (editorState) => {
    setEditor(editorState);
  };
  const onFinish = (data) => {
    const formData = {
      ...data,
      id: edit.id,
      image: uplodImage !== "" ? uplodImage : edit.image,
      tags: data.tags !== undefined ? data.tags : tags,
      body: editor !== undefined ? getHtml(editor) : edit.body,
    };
    console.log(formData);
    editBlog({ ...data, ...formData }, dispatch, navigate);
  };
  return (
    <div>
      <Modal
        title={`Edit ${name}`}
        visible={viewEdit}
        onCancel={onCloseEdit}
        footer={false}
      >
        <div>
          <Form layout="vertical" className="w-100" onFinish={onFinish}>
            <Form.Item
              label="Blog Title"
              name="title"
              rules={[{ required: true }]}
              initialValue={edit.title}
            >
              <Input required placeholder="Blog Title" />
            </Form.Item>
            <Form.Item
              initialValue={edit.body}
              label="Blog Content"
              rules={[{ required: true }]}
            >
              <Editor
                editorState={editor}
                editorClassName="px-3 border border-gray-2"
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorStyle={{
                  height: 300,
                  overflow: "auto",
                }}
                onEditorStateChange={onEditorStateChange}
              />
            </Form.Item>
            <Form.Item required label="Upload Image">
              <Input
                type="file"
                placeholder="Image"
                onChange={({ target: { files } }) => {
                  const file = files[0];
                  uploadFile({
                    file,
                    setFile: setUploadImage,
                    setLoading,
                    dispatch,
                  });
                }}
              />
            </Form.Item>
            <Form.Item
              label="Blog Category"
              name="category_id"
              initialValue={category?.id}
              rules={[{ required: true }]}
            >
              <Select name="category_id">
                {blogCats?.map((d) => (
                  <Select.Option value={d.id} key={d.id}>
                    {d.category_name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Blog Position"
              name="position"
              initialValue={edit.position}
            >
              <Input type="number" />
            </Form.Item>
            <Form.Item
              label="Blog Tags"
              name="tags"
              initialValue={tags?.map((obj) => obj.id)}
            >
              <Select
                mode="multiple"
                style={{
                  width: "100%",
                }}
                placeholder="select blog tag"
                optionLabelProp="label"
              >
                {blogTags?.map((e, i) => (
                  <Select.Option key={i} value={e.id}>
                    {e.tag_name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item>
              <Button
                htmlType="submit"
                loading={loading || mLoading}
                size="large"
              >
                submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

export const ViewModal = ({ name, onCloseView, viewOp, viewOne }) => {
  // const [viewFile, setViewFile] = useState(false)
  const { category, tags } = viewOp;
  // const onView = () =>{
  //   setViewFile(true)
  // }
  // const closeView = () =>{
  //   setViewFile(false)
  // }
  return (
    <div>
      <Modal
        title={`View ${viewOp?.title}`}
        visible={viewOne}
        onCancel={onCloseView}
        footer={false}
      >
        <div className="row">
          <div>
            <Image width={200} alt={viewOp?.title} src={viewOp?.image} />
            <h4>Title: {viewOp?.title}</h4>
            <p dangerouslySetInnerHTML={{ __html: viewOp.body }} />
            <p>
              <b>Category:</b> <span />
              <span>
                <Tag color="success">{category?.category_name}</Tag>
              </span>
            </p>
            <p>
              <strong>Tags:</strong> <span />
              <span>
                {tags?.map((d) => (
                  <Tag key={d?.id}>{d?.tag_name}</Tag>
                ))}
              </span>
            </p>

            {/* <Button onClick={onView}><EyeOutlined /> View File</Button>
            {viewFile &&
              <>
                <CloseOutlined onClick={closeView} />
                <iframe
                title='ppt'
                className='w-100 h-100'
                src={viewOp?.blog_file}
              />
              </>
            } */}
            <a
              className="mx-2"
              href={viewOp?.blog_file}
              download
              style={{ border: "solid 1px", padding: 5 }}
            >
              <DownloadOutlined /> Download File
            </a>
          </div>
        </div>
      </Modal>
    </div>
  );
};
