import React from 'react'
import useDashboard from '../hooks/useDashboard';
// state
import { useSelector } from 'react-redux';

// components
import CreateCompaniesFiles, { ViewModal, EditModal} from '../misc/CreateCompaniesFile';
import { FaRegBuilding } from 'react-icons/fa';
import { Table, Tag, Tooltip, Button } from 'antd'

// constants and icon
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';

const CompaniesFiles = () => {
    const companiesFile = useSelector(({companiesFile}) => companiesFile)
    const { companiesFiles } = companiesFile
    console.log(companiesFile)
    const loading = useSelector(({loading}) => loading.loading);
  const {onLoad,  editOpen, viewOpen, setEdit, edit, viewEdit, onCloseView, viewOne, viewOp, onCloseEdit, visible, setVisible, onCloseModal} = useDashboard();
  React.useEffect(() => {
    onLoad();
  }, [onLoad]);
  const delOpport = id => {
    console.log(id)
  }
  

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: e => (
        <p className="bg-light position text-dark text-truncate" style={{ maxWidth: '150px' }}>
          <Tooltip placement="topLeft" title={e}>
            {e}
          </Tooltip>
        </p>
      )
    },
    {
      title: 'Company',
      key: 'body',
      dataIndex: 'body',
      render: e => (
        <p className="bg-light position text-dark text-truncate" style={{ maxWidth: '250px' }}>
          <Tooltip placement="topLeft" title={e}>
            {e}
          </Tooltip>
        </p>
      )
    },
    {
      title: 'Issuing Body',
      dataIndex: 'issuing_body',
      key: 'issuing_body',
    },
    {
      title: 'Issuing Data',
      dataIndex: 'issuing_date',
      key: 'issuing_date',
    },
    {
      title: 'Expiring Date',
      dataIndex: 'expiring_date',
      key: 'expiring_date',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',      
      render: e => (
        <Tag color={`${e === "1" ? 'success':'error'}`}>{e === "1" ? 'Active':'Inactive'}</Tag>
      )
    },
    {
      title: 'Action',
      key: 'id',
      dataIndex: 'id',
      render: (id, data) => (
        <>
          <Tooltip title="View CompaniesFiles">
            <Button type="primary" shape="circle" icon={<EyeOutlined />} onClick={() => viewOpen(data)} />
          </Tooltip>
          <Tooltip title="Edit CompaniesFiles">
            <Button className='bg-success text-white' shape="circle" icon={<EditOutlined />} onClick={() => editOpen(data)} />
          </Tooltip>
          <Tooltip title="Delete CompaniesFiles">
            <Button className='bg-danger text-white' shape="circle" icon={<DeleteOutlined />} onClick={() => delOpport(id)} />
          </Tooltip>
        </>
      ),
    },
  ];

  return (
    <div style={{flex: 1}}>
      <h2>CompaniesFiles Page</h2>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <div>
          <Button type="primary" icon={<FaRegBuilding />} size='large' onClick={() => setVisible(true)}>
            Create
          </Button>
        </div>
      </div>
      <div style={{display: 'flex', flex: 1, flexDirection: 'column', marginTop: 20}} className="kit__utils__table">
        <Table 
            columns={columns}
            rowKey="id"
            dataSource={companiesFiles}
            loading={loading}
            className="mb-2"
            pagination={false} 
        />
      </div>
      <CreateCompaniesFiles visible={visible} onHandler={onCloseModal} />
      <EditModal edit={edit} setEdit={setEdit} viewEdit={viewEdit} onCloseEdit={onCloseEdit} name='CompaniesFiles' />
      <ViewModal viewOne={viewOne} viewOp={viewOp} onCloseView={onCloseView} name='CompaniesFiles' />
    </div>
  );
}

export default CompaniesFiles
