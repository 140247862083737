import React from "react";
import useCreateBlog from "../hooks/useCreateBlog";
import useUploadFiles from "../../../services/hooks/useUploadFiles";

// components
import { Select, Modal, Form } from "antd";
import RichText from "../../../component/element/forms/RichText";
import TextInput from "../../../component/element/forms/TextInput";
import ButtonComponent from "../../../component/element/forms/Button";
import Upload from "../../../component/modals/upload";

// constants
import { MdSubtitles } from "react-icons/md";

// state
import { useDispatch, useSelector } from "react-redux";
import {
  getBlogCats,
  getBlogTags,
  createBlog,
} from "../../../services/apis/blog";

const CreateBlog = ({ visible, onHandler }) => {
  const dispatch = useDispatch();
  const blogTags = useSelector(({ blog }) => blog?.blogTags);
  const blogCats = useSelector(({ blog }) => blog?.blogCats);
  const { uploadAvatar, loading: imageLoader } = useUploadFiles();
  const { data, dataSet, setData, error, setError, onSetInput, onValidate } =
    useCreateBlog();

  const handleOk = () => {
    if (onValidate() === true) {
      createBlog(data, dispatch);
      onHandler();
    }
  };

  const onLoad = React.useCallback(() => {
    getBlogTags(dispatch);
    getBlogCats(dispatch);
  }, [dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <Modal
      title="Add Blog"
      open={visible}
      footer={false}
      onCancel={onHandler}
      width="90%"
      centered
    >
      <Form layout="vertical" className="w-100" onFinish={handleOk}>
        <div className="w-full mb-3">
          <TextInput
            name="title"
            label="Blog Title"
            value={data.title}
            placeholder="Blog Title"
            icon={<MdSubtitles size={20} />}
            onChange={onSetInput}
          />
        </div>

        <div className="flex w-full items-center justify-between">
          <div className="w-[32%]">
            <Form.Item
              label="Blog Category"
              name="category_id"
              rules={[{ required: true }]}
            >
              <Select>
                {blogCats?.map((d) => (
                  <Select.Option value={d.id} key={d.id}>
                    {d.category_name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>

          <div className="w-[32%]">
            <Form.Item label="Blog Tags" name="tags">
              <Select
                mode="multiple"
                className="w-[100%]"
                placeholder="select blog tag"
                optionLabelProp="label"
              >
                {blogTags?.map((e, i) => (
                  <Select.Option key={i} value={e.id}>
                    {e.tag_name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>

          <div className="w-[32%]">
            <TextInput
              name="position"
              label="Blog Position"
              value={data.position}
              placeholder="Blog Position"
              type="number"
              icon={<MdSubtitles size={20} />}
              onChange={onSetInput}
            />
          </div>
        </div>

        <div className="flex w-full justify-between items-center">
          <div className="w-[60%]">
            <RichText
              value={data.body}
              placeholder="Enter Body"
              onChange={(e) => dataSet((prev) => ({ ...prev, body: e }))}
            />
          </div>

          <div className="w-[38%]">
            <Upload
              title="Upload Image"
              name="blog_file"
              loading={imageLoader}
              status={error.blog_file}
              completed={data.blog_file}
              onChange={async (res) => {
                setError((prev) => ({ ...prev, blog_file: "" }));
                const response = await uploadAvatar(res);
                if (typeof response === "string") {
                  setData((prev) => ({ ...prev, blog_file: response }));
                } else {
                  setError((prev) => ({ ...prev, blog_file: true }));
                }
              }}
            />
          </div>
        </div>

        <div className="flex justify-end items-center">
          <div className="w-[20%]">
            <ButtonComponent title="Submit" onClick={handleOk} />
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default CreateBlog;
