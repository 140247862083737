import React from 'react';

// components
import { Layout } from 'antd';
import ContentComponent from './components/ContentComponent';
import SideNav from './components/SideNav';
  
const Theme = ({children}) => (
  <Layout hasSider>
    <SideNav />
    <ContentComponent>
      {children}
    </ContentComponent>
  </Layout>
);
  
export default Theme;