/* eslint-disable camelcase */
import React from "react";
import useDetails from "../hooks/useDetails";

// react router
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

// compoenents
import { Avatar, Button, Card, Modal, Spin, Table, Tag } from "antd";
// import { Bounce } from 'react-reveal'

// state
import { useDispatch, useSelector } from "react-redux";
import PageHeading from "../../../component/heading";
import {
  ExclamationCircleOutlined,
  LeftOutlined,
  UserOutlined,
} from "@ant-design/icons";
import DataDisplay from "../../../component/dataDisplay";
import FileLog from "../../../component/fileLog";
import { SetAdminCompany } from "../../../services/apis/user";
import { setRemoveUser } from "../../../services/apis/company";
import { FaRegBuilding } from "react-icons/fa";
import CreateUser from "../../users/create";

const { confirm } = Modal;

const CompanyDetails = () => {
  const location = useLocation();
  const [visible, setVisible] = React.useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { onLoad } = useDetails();
  const { state } = location;
  const {
    company_email,
    company_name,
    company_phone,
    status,
    company_website,
    premium_status,
    rc_number,
    banner,
  } = state;
  const companies = useSelector(({ company }) => company);
  const { companyDoc, companyEmp } = companies;
  const loading = useSelector(({ loading }) => loading.loading);
  const screenWidth = window.innerWidth;
  const data = {
    company_email,
    company_name,
    company_phone,
    status,
    company_website,
    premium_status,
    rc_number,
    banner,
  };

  const setAdmin = ({ id, name, user }) => {
    const formData = {
      email: "",
    };
    confirm({
      title: `Do you want to set ${name} as ${company_name} Company Admin ?`,
      icon: <ExclamationCircleOutlined />,
      content: <h4>{company_name}</h4>,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        SetAdminCompany(
          { ...data, ...formData, email: user.email },
          dispatch,
          navigate
        );
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  const setRemove = ({ user_id, name }) => {
    const data = {
      id: "",
    };
    confirm({
      title: `Are you sure you want to remove ${name} from ${company_name} ?`,
      icon: <ExclamationCircleOutlined />,
      content: <h4>{name}</h4>,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        setRemoveUser({ ...data, id: user_id }, dispatch, navigate);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      render: (e, data) => (
        <Avatar
          src={
            data.avatar === ""
              ? "https://joeschmoe.io/api/v1/random"
              : data.avatar
          }
          alt="user"
        />
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (e, data) => <p>{data?.user.email}</p>,
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (e, data) => (
        <>
          {data?.user.role === "0" ? (
            <Tag color="blue">User</Tag>
          ) : (
            <Tag color="green">Company Admin</Tag>
          )}
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (e, data) => (
        <>
          {data?.user.status === "0" ? (
            <Tag color="green">Active</Tag>
          ) : (
            <Tag color="red">Inactive</Tag>
          )}
        </>
      ),
    },
    {
      title: "ACTION",
      key: "action",
      render: (da, item) => (
        <>
          {item?.user.role === "0" && (
            <Button
              style={{ marginLeft: 2 }}
              type="primary"
              onClick={() => setAdmin(item)}
            >
              Set as Admin
            </Button>
          )}
          <Button
            style={{ marginLeft: 2 }}
            type="danger"
            onClick={() => setRemove(item)}
          >
            Remove
          </Button>
        </>
      ),
    },
  ];
  const onCloseModal = () => {
    setVisible(false);
  };

  React.useEffect(() => {
    onLoad(id);
  }, [id, onLoad]);
  return (
    <Spin spinning={loading} tip="loading..." size="large">
      <div className="w-100">
        <div className="d-flex justify-content-between">
          <PageHeading
            title={`${company_name} Details`}
            link="/company"
            img="/settings.png"
            wClass="w-50"
            name="Company"
          />
          {/* onClick={()=> history.goBack()} */}
          <Link to="/company">
            <Button type="text" icon={<LeftOutlined />}>
              Back
            </Button>
          </Link>
        </div>
        <div className="row">
          <div className="col-lg-8">
            <Card loading={loading} className="bg-transparent border-0">
              <div className="row">
                <div className="col-md-3 pe-0 bg-transparent text-center">
                  <Avatar
                    className="img-fluid mx-auto bg-transparent"
                    alt="zero user"
                    src={`/avatar.png`}
                    shape="square"
                    size={180}
                    icon={<UserOutlined />}
                  />
                </div>
                <div className="col-md-9">
                  <ul className="list-group rounded-0">
                    <DataDisplay title="Name" detail={company_name} />
                    <DataDisplay title="Email" detail={company_email} />
                    <DataDisplay title="Mobile Number" detail={company_phone} />
                    <DataDisplay
                      title="Company Status"
                      detail={
                        status === "0" ? <Tag>Active</Tag> : <Tag>Inactive</Tag>
                      }
                    />
                  </ul>
                </div>
                <div className="col-md-12">
                  <ul className="list-group rounded-0">
                    <DataDisplay title="RC Number" detail={rc_number} />
                    <DataDisplay title="Website" detail={company_website} />
                    <DataDisplay
                      title="Premium Status"
                      detail={premium_status}
                    />
                    {/* <DataDisplay title="Local Govt" detail={profile.lg} />
                    <DataDisplay title="Marital Status" detail={profile.marital} /> */}
                  </ul>
                </div>
              </div>
            </Card>
          </div>

          <div className="col-lg-4">
            <p className="fs-5 fw-bold mt-4 mb-0">Settings Actions</p>
            <FileLog
              width={screenWidth <= 500 ? "100%" : "378"}
              loading={loading}
              profile="company"
              company={data}
              data={companyDoc}
            />
            {/* {role === 0 &&
                <SetAdminLog width={screenWidth <= 500 ? "100%" : "378"} loading={loading} profile={profile} company={data} email={email} />
              } */}
          </div>
        </div>
        <div className="row">
          <div className="kit__utils__table">
            {/* <Table 
                  // columns={columns}
                  rowKey="id"
                  // dataSource={companyEmp}
                  loading={loading}
                  className="mb-2"
                  pagination={false} 
                /> */}
            {/* <Bounce bottom> */}
            <h6>Company({company_name}) Employees</h6>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <Button
                  type="primary"
                  size="large"
                  onClick={() => setVisible(true)}
                >
                  <div className="flex justify-center items-center w-full">
                    <FaRegBuilding /> Add Employees
                  </div>
                </Button>
              </div>
            </div>
            <Table
              columns={columns}
              rowKey="id"
              dataSource={companyEmp}
              loading={loading}
              className="mb-2"
              pagination={false}
            />

            <CreateUser
              companyID={id}
              visible={visible}
              onHandler={onCloseModal}
            />

            {/* <BoostTable loading={loading} data={companyEmp} /> */}
            {/* </Bounce> */}
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default CompanyDetails;
