import React from "react"; // , { useState }
import useUploadFiles from "../../../services/hooks/useUploadFiles";
import useCreateService from "../hooks/useCreateServices";

// components
import Tab from "../../../component/ui/tabs/Tabs";
import { Input, Modal, Form, Button, Image, Tag } from "antd";
import TextMultipleInput from "../../../component/element/forms/TextMultipleInput";
import Upload from "../../../component/modals/upload";
import TextArea from "antd/lib/input/TextArea";

// state
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createService, editService } from "../../../services/apis/services";

const CreateService = ({ visible, onHandler }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { uploadAvatar, loading } = useUploadFiles();
  const {
    data,
    date,
    error,
    setError,
    setData,
    onLoad,
    onGetCountry,
    onChangeText,
    onValidate,
  } = useCreateService();

  const handleOk = () => {
    const validate = onValidate();
    if (validate) {
      const formData = { ...data, expire_date: date };
      createService({ ...data, ...formData }, dispatch, navigate);
      onHandler?.();
    }
  };

  React.useEffect(() => {
    onLoad();
    onGetCountry();
  }, [onLoad, onGetCountry]);

  return (
    <Modal
      title="Add Service"
      open={visible}
      onOk={handleOk}
      onCancel={onHandler}
    >
      <Form layout="vertical" className="w-100">
        <div className="row">
          <div style={{ margin: "0" }}>
            <Form.Item label="Service Title">
              <Input
                placeholder="Service Title"
                value={data.name}
                name="name"
                onChange={onChangeText}
              />
            </Form.Item>
          </div>

          <Upload
            title="Upload Image"
            name="description"
            loading={loading}
            status={error.description}
            completed={data.description}
            onChange={async (res) => {
              setError((prev) => ({ ...prev, description: "" }));
              const response = await uploadAvatar(res);
              if (typeof response === "string") {
                setData((prev) => ({ ...prev, description: response }));
              } else {
                setError((prev) => ({ ...prev, description: true }));
              }
            }}
          />

          {data.packages.length > 0 && (
            <div className="border py-2 px-3 my-3 rounded-lg flex">
              {data.packages.map((item, index) => (
                <Tab key={index} value={item} />
              ))}
            </div>
          )}

          <TextMultipleInput
            container="my-2"
            onChange={(res) =>
              setData((prev) => ({
                ...prev,
                packages: [...data.packages, res],
              }))
            }
          />
        </div>
      </Form>
    </Modal>
  );
};

export default CreateService;

export const EditModal = ({ name, edit, setEdit, onCloseEdit, viewEdit }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onFinish = (data) => {
    const formData = {
      ...data,
    };
    editService({ ...data, ...formData }, dispatch, navigate);
    console.log(formData);
  };
  return (
    <div>
      <Modal
        title={`Edit ${name}`}
        visible={viewEdit}
        onCancel={onCloseEdit}
        footer={false}
      >
        <div>
          <Form
            layout="vertical"
            className="w-100"
            onFinish={() => onFinish(edit)}
          >
            <div className="row">
              <div style={{ margin: "0" }}>
                <Form.Item label="Edit Service Title">
                  <Input
                    required
                    placeholder="Enter Service Title"
                    value={edit.name}
                    onChange={({ target: { value } }) =>
                      setEdit({ ...edit, name: value })
                    }
                  />
                </Form.Item>
              </div>
              <div style={{ margin: "0" }}>
                <Form.Item label="Edit Service Description">
                  <TextArea
                    required
                    rows={4}
                    placeholder="Enter Service Description"
                    value={edit.body}
                    onChange={({ target: { value } }) =>
                      setEdit({ ...edit, body: value })
                    }
                  />
                </Form.Item>
              </div>
              <div style={{ margin: "0" }}>
                <Form.Item>
                  <Button htmlType="submit" size="large">
                    Update
                  </Button>
                </Form.Item>
              </div>
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

export const ViewModal = ({ name, onCloseView, viewOp, viewOne }) => {
  return (
    <div>
      <Modal
        title={`View ${name}`}
        visible={viewOne}
        onCancel={onCloseView}
        footer={false}
      >
        <div className="row">
          <div>
            <Image width={200} src={viewOp?.image} />
            <h4>Title: {viewOp?.name}</h4>
            <p>Description: {viewOp?.body}</p>
            <p>Type: {viewOp?.address}</p>
            <p>
              <Tag color="success">Active</Tag>
            </p>
          </div>
        </div>
      </Modal>
    </div>
  );
};
