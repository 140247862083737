import React from "react";
import { EditOutlined, EyeOutlined, SettingOutlined } from "@ant-design/icons";
import { Avatar, Card, Tag } from "antd";

const { Meta } = Card;

const FileCard = ({
  file,
  dat,
  openFile,
  name,
  status,
  date,
  body,
  isDate,
}) => (
  <div>
    <Card
      style={{ width: 300 }}
      cover={<img alt="document" src="/document.png" />}
      actions={[
        <SettingOutlined key="setting" />,
        <EditOutlined key="edit" />,
        <EyeOutlined key="ellipsis" onClick={() => openFile(dat)} />,
      ]}
    >
      <Meta
        avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
        title={name}
        description={
          <div>
            <p>
              <b>Issue body:</b> {body}
            </p>
            <p>
              <b>Issue date:</b> {isDate}
            </p>
            <p>
              <b>Expiring date:</b> {date}
            </p>
            <p>
              <b>Status:</b>{" "}
              <Tag color={status === "1" ? "green" : "red"}>
                {status === "1" ? "Active" : "Expired"}
              </Tag>
            </p>
          </div>
        }
      />
    </Card>
  </div>
);

export default FileCard;
