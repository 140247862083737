import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  blogs: [],
  blogTags:[],
  blogCats:[],
  search: [],
  loading: false,
  blog: null,
  last_page: null,
  current_page: null,
  upload:{}
}

export const blog = createSlice({
  name: 'blog',
  initialState,
  reducers: {
    setBlogs: (state, action) => {
      state.blogs = action.payload.data;
      state.last_page = action.payload.last_page
      state.current_page = action.payload.current_page
  },
  setBlog: (state, action) => {
        state.blogs = action.payload;
    },
    setBlogTags: (state, action) => {
          state.blogTags = action.payload;
      },
    setBlogCats: (state, action) => {
          state.blogCats = action.payload;
      },
    setUpload: (state, action) => {
          state.upload = action.payload;
      },
    setSearch: (state, action) => {
        state.blogs = action.payload.data;
    },
    setBlogLoading: (state) => {
        state.loading = true;
    },
    disableBlogLoading: (state) => {
        state.loading = false;
    }
  },
})

// Action creators are generated for each case reducer function
export const { setSearch, setUpload, setBlogTags, setBlogCats, setBlog, setBlogLoading, disableBlogLoading, setBlogs } = blog.actions

export default blog.reducer