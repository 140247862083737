import React from 'react'
import useCreateCompany from '../hooks/useCreateCompany';

// components
import { Input, Modal } from 'antd';
import { Select } from 'antd';
import { AutoComplete } from 'antd';

// state
import { useDispatch, useSelector } from 'react-redux';
import { createCompany } from '../../../services/apis/company';
import { useNavigate } from 'react-router-dom';

const { Option } = Select;

const CreateCompany = ({visible, onHandler}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const companies = useSelector(({company}) => company.companies);
  const loading = useSelector(({company}) => company.loading)
  const states = useSelector(({country}) => country.states?.states)
  const countries = useSelector(({country}) => country.all_countries)
  const {data, setData, onLoad, onGetCountry, getStateCountry, name, setName, onChangeText, onValidate} = useCreateCompany();

  const handleOk = () => {
    const validate = onValidate();
    if (!validate) {
      return;
    }
    const formData = {...data, company_name: data.company_name === '' ? name : data.company_name};
    createCompany({...data, ...formData}, dispatch, navigate)
    onHandler?.()
  };

  const onSearch = (searchText) => {
    return companies?.filter(item => item.company_name.includes(searchText))
  };

  const onSelect = (select_id) => {
    const company = companies?.find(({id}) => id === select_id);
    setName(company.company_name)
    setData(prev => ({...prev, 
      company_name: company.id, 
      company_website: company.company_website, 
      company_email: company.company_email, 
      company_phone: company.company_phone
    }));
  };

  React.useEffect(() => {
    onLoad();
    onGetCountry();
  }, [onLoad, onGetCountry]);

  return (
    <Modal
        title="Register Company"
        open={visible}
        onOk={handleOk}
        confirmLoading={loading}
        onCancel={onHandler}
      >
        <div style={{margin: '15px 0'}}>
          <label>Company Name</label>
          <AutoComplete
            style={{ width: '100%'}}
            onSelect={onSelect}
            onSearch={onSearch}
            value={name}
            placeholder="Company Name"
            onChange={text => setName(text)}
          >
            {companies.length > 0 && companies.map(item => 
              <AutoComplete.Option key={item.id} value={item.id}>{item.company_name}</AutoComplete.Option>
            )}
          </AutoComplete>
        </div>

        <div style={{margin: '15px 0'}}>
          <label>Company Phone</label>
          <Input 
            placeholder="Basic usage" 
            value={data.company_phone} 
            name="company_phone"
            onChange={onChangeText} 
          />
        </div>

        <div style={{margin: '15px 0'}}>
          <label>Company Email</label>
          <Input 
            placeholder="Basic usage" 
            value={data.company_email}
            name="company_email"
            onChange={onChangeText} 
          />
        </div>

        <div style={{margin: '15px 0'}}>
          <label>Company Website</label>
          <Input
            placeholder="Company Website"
            value={data.company_website}
            name="company_website"
            onChange={onChangeText} 
          />
        </div>

        <div style={{margin: '15px 0'}}>
          <label>RC Number:</label>
          <Input
            placeholder="Company RC Number"
            value={data.rc_number}
            name="rc_number"
            onChange={onChangeText} 
          />
        </div>

        <div style={{margin: '15px 0'}}>
          <label>Company Country</label>
          <Select 
            defaultValue="" style={{ width: '100%' }} 
            name="company_country"
            onChange={text => {
              setData(prev => ({...prev, company_country: text}))
              getStateCountry(text);
            }}
          >
            <Option value="">Select A Country</Option>
            {countries.length > 0 && countries.map((item, index) => <Option key={index} value={item.id}>{item.name}</Option>)}
          </Select>
        </div>

        <div style={{margin: '15px 0'}}>
          <label>Company Address</label>
          <Input 
            placeholder="Company Address" 
            value={data.company_address}
            name="company_address"
            onChange={onChangeText}
          />
        </div>

        <div style={{margin: '15px 0'}}>
          <label>Company State</label>
          <Select
            defaultValue=""
            style={{ width: '100%' }}
            name="company_state"
            onChange={text => setData(prev => ({...prev, company_state: text}))}>
            <Option value="">Select State</Option>
            {states?.length > 0 && states.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
          </Select>
        </div>
      </Modal>
  )
}

export default CreateCompany
