import React from 'react'
import useHome from '../hooks/useHome';

// components
import { Button, Table } from 'antd';

// constants and iconsy7ifghknk ,ON
import { FaArrowLeft, FaArrowRight, FaRegBuilding } from "react-icons/fa";

// state5v75ty
import { useSelector } from 'react-redux';
import CreateUser, { UserCompanyModal } from '../create';

const User = () => {
  const users = useSelector(({user}) => user.users);
  const companies = useSelector(({company}) => company.companies);
  const loading = useSelector(({loading}) => loading.loading);
  const last_page = useSelector(({user}) => user.last_page)
  const current_page = useSelector(({user}) => user.current_page)
  const {page, columns, setPage, onCloseOpenModal, openD, open, visible, setVisible, onCloseModal} = useHome();
  return (
    <div style={{flex: 1}}>
      <h2>Users Page</h2>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <div>
          <Button type="primary" icon={<FaRegBuilding />} size='large' onClick={() => setVisible(true)}>
            Create
          </Button>
        </div>
      </div>
      <div style={{display: 'flex', flex: 1, flexDirection: 'column', marginTop: 20}} className="kit__utils__table">
        <Table 
            columns={columns.current}
            rowKey="id"
            dataSource={users}
            loading={loading}
            className="mb-2"
            pagination={false} 
        />
        <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', paddingTop: 20, paddingBottom: 20}}>
          <Button
            type="primary"
            icon={<FaArrowLeft />}
            loading={loading}
            onClick={() => page > 1 && setPage(page - 1)}
          >
            {' '}Prev
          </Button>

          <span style={{paddingLeft: 10, paddingRight: 10}}>{current_page} | {last_page}</span>

          <Button
            type="primary"
            icon={<FaArrowRight />}
            loading={loading}
            onClick={() => page <= last_page && setPage(page + 1)}
          >
            {' '}Next
          </Button>
        </div>
      </div>
      {/* <div>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <div>
            <Button type="primary" icon={<FaRegBuilding />} size='large' onClick={() => setVisible(true)}>
              Create
            </Button>
          </div>
        </div>
        <Table columns={columns.current} dataSource={users} loading={loading} pagination={false} rowKey="id" />
        <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', paddingTop: 20, paddingBottom: 20}}>
          <Button
            type="primary"
            icon={<FaArrowLeft />}
            loading={loading}
            onClick={() => page > 1 && setPage(page - 1)}
          >
            {' '}Prev
          </Button>

          <span style={{paddingLeft: 10, paddingRight: 10}}>{current_page} | {last_page}</span>

          <Button
            type="primary"
            icon={<FaArrowRight />}
            loading={loading}
            onClick={() => page <= last_page && setPage(page + 1)}
          >
            {' '}Next
          </Button>
        </div>
      </div> */}
      <CreateUser visible={visible} onHandler={onCloseModal}  />
      <UserCompanyModal visible={open} openD={openD} onHandler={onCloseOpenModal} companies={companies} />
    </div>
  );
};

export default User;
