import React from 'react'

// router
import { useNavigate } from 'react-router-dom';

// state
import { useDispatch } from 'react-redux';
import { getAllCompany } from '../../../services/apis/company';
import { getAllCountry, getState } from '../../../services/apis/country';

const useCreateCompany = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [name, setName] = React.useState('')
  const [data, setData] = React.useState({
    company_name: "",
    company_phone: "",
    company_email: "",
    company_website: "",
    company_address: "",
    company_state: "",
    rc_number:'',
    company_country: "",
  })

  const [error, setError] = React.useState({
    company_name: "",
    company_phone: "",
    company_email: "",
    company_website: "",
    company_address: "",
    company_state: "",
    company_country: "",
  })

  const onChangeText = (text) => {
    const {value, name} = text.target;
    setData(prev => ({...prev, [name]: value}));
  }

  const onValidate = () => {
    let err;
    if (!data.company_name && !name) {
      err = true;
    }
    if (!data.company_address) {
      err = true;
    }
    if (!data.company_country) {
      err = true;
    }
    if (!data.company_email) {
      err = true;
    }
    if (!data.company_phone) {
      err = true;
    }
    if (!data.company_state) {
      err = true;
    }
    if (!data.company_website) {
      err = true;
    }
    if (err) {
      return false;
    }
    return true;
  }

  const getStateCountry = (id) => {
    getState(id, dispatch, navigate);
  }

  const onLoad = React.useCallback(() => {
    getAllCompany(dispatch, navigate);
  }, [dispatch, navigate])

  const onGetCountry = React.useCallback(() => {
    getAllCountry(dispatch, navigate)
  }, [dispatch, navigate]);

  return {data, setData, error, setError, onLoad, onGetCountry, getStateCountry, name, setName, onChangeText, onValidate}
}

export default useCreateCompany
   