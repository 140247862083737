import axiosInstance from "../../utility/axiosInstance";

// state
import { openNotification } from "../../modules";
import { setLoading, disableLoading } from "../../store/slices/loading";
import {
  disableBlogLoading,
  setBlogLoading,
  setUpload,
  setBlog,
  setBlogTags,
  setBlogCats,
} from "../../store/slices/blog";

export const getAllBlog = async (dispatch, navigate) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance(navigate).get("/get-blogs");
    if (response.data) {
      const { data } = response.data;
      dispatch(setBlog(data));
    }
  } catch (err) {
    const { data } = err.response;
    openNotification({
      type: "error",
      title: "Incorrect Credentials",
      description: data.message,
    });
  }
  dispatch(disableLoading());
};

export const getBlogTags = async (dispatch, navigate) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance(navigate).get("/get-tags");
    if (response.data) {
      const { data } = response;
      dispatch(setBlogTags(data));
    }
  } catch (err) {
    const { data } = err.response;
    console.log(err.response);
    openNotification({
      type: "error",
      title: "Incorrect Credentials",
      description: data.message,
    });
  }
  dispatch(disableLoading());
};

export const getBlogCats = async (dispatch, navigate) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance(navigate).get("/get-categories");
    if (response.data) {
      const { data } = response;
      dispatch(setBlogCats(data));
    }
  } catch (err) {
    const { data } = err.response;
    console.log(err.response);
    openNotification({
      type: "error",
      title: "Incorrect Credentials",
      description: data.message,
    });
  }
  dispatch(disableLoading());
};

export const createBlogTag = async (data, dispatch, navigate) => {
  console.log(data);
  dispatch(setBlogLoading());
  try {
    const response = await axiosInstance(navigate).post("/create-tag", data);
    if (response.data) {
      openNotification({
        type: "success",
        title: "Successful!",
        description: response.data.message,
      });
    }
    setTimeout(() => {
      window.location.reload();
    }, 500);
  } catch (err) {
    const { data } = err.response;
    openNotification({
      type: "error",
      title: "Incorrect Credentials",
      description: data.message,
    });
  }
  dispatch(disableBlogLoading());
};

export const createBlogCat = async (data, dispatch, navigate) => {
  dispatch(setBlogLoading());
  try {
    const response = await axiosInstance(navigate).post(
      "/create-category",
      data
    );
    if (response.data) {
      openNotification({
        type: "success",
        title: "Successful!",
        description: response.data.message,
      });
    }
    setTimeout(() => {
      window.location.reload();
    }, 500);
  } catch (err) {
    const { data } = err.response;
    openNotification({
      type: "error",
      title: "Incorrect Credentials",
      description: data.message,
    });
  }
  dispatch(disableBlogLoading());
};

export const setRemoveBlogTag = async (data, dispatch, navigate) => {
  const { id } = data;
  dispatch(setBlogLoading());
  try {
    const response = await axiosInstance(navigate).get(`/delete-tag/${id}`);
    if (response.data) {
      openNotification({
        type: "success",
        title: "Successful!",
        description: response.data.message,
      });
    }
    // setTimeout(() => {
    //     window.location.reload()
    // }, 500)
  } catch (err) {
    const { data } = err.response;
    openNotification({
      type: "error",
      title: "Incorrect Credentials",
      description: data.message,
    });
  }
  dispatch(disableLoading());
};

export const setRemoveBlogCat = async (data, dispatch, navigate) => {
  const { id } = data;
  dispatch(setBlogLoading());
  try {
    const response = await axiosInstance(navigate).get(
      `/delete-category/${id}`
    );
    if (response.data) {
      openNotification({
        type: "success",
        title: "Successful!",
        description: response.data.message,
      });
    }
    // setTimeout(() => {
    //     window.location.reload()
    // }, 500)
  } catch (err) {
    const { data } = err.response;
    openNotification({
      type: "error",
      title: "Incorrect Credentials",
      description: data.message,
    });
  }
  dispatch(disableLoading());
};

export const setRemoveBlog = async (data, dispatch, navigate) => {
  const { id } = data;
  dispatch(setBlogLoading());
  try {
    const response = await axiosInstance(navigate).get(`/delete-blog/${id}`);
    if (response.data) {
      openNotification({
        type: "success",
        title: "Successful!",
        description: response.data.message,
      });
    }
    setTimeout(() => {
      window.location.reload();
    }, 500);
  } catch (err) {
    const { data } = err.response;
    openNotification({
      type: "error",
      title: "Incorrect Credentials",
      description: data.message,
    });
  }
  dispatch(disableLoading());
};

export const editBlogTag = async (data, dispatch, navigate) => {
  dispatch(setBlogLoading());
  try {
    const response = await axiosInstance(navigate).put(`/update-tag`, data);
    if (response.data) {
      openNotification({
        type: "success",
        title: "Successful!",
        description: response.data.message,
      });
    }
    setTimeout(() => {
      window.location.reload();
    }, 500);
  } catch (err) {
    const { data } = err.response;
    openNotification({
      type: "error",
      title: "Incorrect Credentials",
      description: data.message,
    });
  }
  dispatch(disableBlogLoading());
};

export const editBlogCat = async (data, dispatch, navigate) => {
  dispatch(setBlogLoading());
  try {
    const response = await axiosInstance(navigate).put(
      `/update-category`,
      data
    );
    if (response.data) {
      openNotification({
        type: "success",
        title: "Successful!",
        description: response.data.message,
      });
    }
    setTimeout(() => {
      window.location.reload();
    }, 500);
  } catch (err) {
    const { data } = err.response;
    openNotification({
      type: "error",
      title: "Incorrect Credentials",
      description: data.message,
    });
  }
  dispatch(disableBlogLoading());
};
export const createBlog = async (formData, dispatch, navigate) => {
  dispatch(setBlogLoading());
  try {
    const response = await axiosInstance(navigate).post(
      "/create-blog",
      formData
    );

    if (response.data) {
      openNotification({
        type: "success",
        title: "Successful!",
        description: response.data.message,
      });
    }
  } catch (err) {
    const { data } = err.response;
    openNotification({
      type: "error",
      title: "Cannot create blog.",
      description: data.message,
    });
  }
  dispatch(disableBlogLoading());
};

export const upload = async (formData, dispatch, navigate) => {
  dispatch(setBlogLoading());
  try {
    const response = await axiosInstance(navigate).post("/upload", formData);
    if (response.data) {
      openNotification({
        type: "success",
        title: "Successful!",
        description: response.data.message,
      });
      const { data } = response.data;
      dispatch(setUpload(data));
    }
  } catch (err) {
    const { data } = err.response;
    openNotification({
      type: "error",
      title: "Incorrect Credentials",
      description: data.message,
    });
  }
  dispatch(disableBlogLoading());
};

export const editBlog = async (data, dispatch, navigate) => {
  dispatch(setBlogLoading());
  try {
    const response = await axiosInstance(navigate).put(`/update-blog`, data);
    if (response.data) {
      openNotification({
        type: "success",
        title: "Successful!",
        description: response.data.message,
      });
    }
    setTimeout(() => {
      window.location.reload();
    }, 500);
  } catch (err) {
    const { data } = err.response;
    openNotification({
      type: "error",
      title: "Incorrect Credentials",
      description: data.message,
    });
  }
  dispatch(disableBlogLoading());
};

export const destroy_blog = async (dispatch, data) => {
  dispatch(setBlogLoading());
  try {
    const response = await axiosInstance().post("/delete-blog", data);
    // if (response.data) {
    //   openNotification({
    //     type: "success",
    //     title: "Successful!",
    //     description: response.data.message,
    //   });
    // }
    // setTimeout(() => {
    //   window.location.reload();
    // }, 500);

    console.log(response?.data);
  } catch (err) {
    const { data } = err.response;
    console.log(data);
    // openNotification({
    //   type: "error",
    //   title: "Incorrect Credentials",
    //   description: data.message,
    // });
  }
  dispatch(disableBlogLoading());
};
