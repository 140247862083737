import axiosInstance from "../../utility/axiosInstance";

// state
import { openNotification } from "../../modules";
import { setLoading, disableLoading } from "../../store/slices/loading";
import {
  disableOpportunityLoading,
  setOpportunityLoading,
  setUpload,
  setOpportunity,
  setOpportunityTypes,
} from "../../store/slices/opportunity";

export const getAllOpportunity = async (dispatch, navigate) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance(navigate).get("/get-opportunities");
    if (response.data) {
      const { data } = response.data;
      dispatch(setOpportunity(data));
    }
  } catch (err) {
    const { data } = err.response;
    openNotification({
      type: "error",
      title: "Incorrect Credentials",
      description: data.message,
    });
  }
  dispatch(disableLoading());
};

export const getOpportunityTypes = async (dispatch, navigate) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance(navigate).get(
      "/get-opportunity_types"
    );
    if (response.data) {
      const { data } = response;
      dispatch(setOpportunityTypes(data));
    }
  } catch (err) {
    const { data } = err.response;
    console.log(err.response);
    openNotification({
      type: "error",
      title: "Incorrect Credentials",
      description: data.message,
    });
  }
  dispatch(disableLoading());
};

export const createOpportunityType = async (data, dispatch, navigate) => {
  console.log(data);
  dispatch(setOpportunityLoading());
  try {
    const response = await axiosInstance(navigate).post(
      "/create-opportunity_types",
      data
    );
    if (response.data) {
      openNotification({
        type: "success",
        title: "Successful!",
        description: response.data.message,
      });
    }
    setTimeout(() => {
      window.location.reload();
    }, 500);
  } catch (err) {
    const { data } = err.response;
    openNotification({
      type: "error",
      title: "Incorrect Credentials",
      description: data.message,
    });
  }
  dispatch(disableOpportunityLoading());
};

export const setRemoveOppType = async (data, dispatch, navigate) => {
  const { id } = data;
  dispatch(setOpportunityLoading());
  try {
    const response = await axiosInstance(navigate).get(
      `/delete-opportunity-type/${id}`
    );
    if (response.data) {
      openNotification({
        type: "success",
        title: "Successful!",
        description: response.data.message,
      });
    }
    // setTimeout(() => {
    //     window.location.reload()
    // }, 500)
  } catch (err) {
    const { data } = err.response;
    openNotification({
      type: "error",
      title: "Incorrect Credentials",
      description: data.message,
    });
  }
  dispatch(disableLoading());
};

export const createOpportunity = async (formData, dispatch, navigate) => {
  dispatch(setOpportunityLoading());
  try {
    const response = await axiosInstance(navigate).post(
      "/create-opportunities",
      formData
    );
    if (response.data) {
      openNotification({
        type: "success",
        title: "Successful!",
        description: response.data.message,
      });
    }
    setTimeout(() => {
      window.location.reload();
    }, 500);
  } catch (err) {
    const { data } = err.response;
    openNotification({
      type: "error",
      title: "Incorrect Credentials",
      description: data.message,
    });
  }
  dispatch(disableOpportunityLoading());
};

export const upload = async (formData, dispatch, navigate) => {
  dispatch(setOpportunityLoading());
  try {
    const response = await axiosInstance(navigate).post("/upload", formData);
    if (response.data) {
      openNotification({
        type: "success",
        title: "Successful!",
        description: response.data.message,
      });
      const { data } = response.data;
      dispatch(setUpload(data));
    }
  } catch (err) {
    const { data } = err.response;
    openNotification({
      type: "error",
      title: "Incorrect Credentials",
      description: data.message,
    });
  }
  dispatch(disableOpportunityLoading());
};

export const editOpportunity = async (data, dispatch, navigate) => {
  dispatch(setOpportunityLoading());
  try {
    const response = await axiosInstance(navigate).put(
      `/edit-opportunities`,
      data
    );
    if (response.data) {
      openNotification({
        type: "success",
        title: "Successful!",
        description: response.data.message,
      });
    }
    setTimeout(() => {
      window.location.reload();
    }, 500);
  } catch (err) {
    const { data } = err.response;
    openNotification({
      type: "error",
      title: "Incorrect Credentials",
      description: data.message,
    });
  }
  dispatch(disableOpportunityLoading());
};
