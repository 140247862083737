import React from 'react' // , { useState } 
import useCreateRequest from '../hooks/useCreateRequest';

// components
import { Input, Modal, Form, Button, Image, Tag } from 'antd'; //  DatePicker,
// import moment from 'moment';
// state
import { useDispatch } from 'react-redux';
// import { createCompany } from '../../../services/apis/company';
import { useNavigate } from 'react-router-dom';
// import useUpload from '../../../services/hooks/useUpload';
// import { CloseOutlined, DownloadOutlined, EyeOutlined } from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';
import { createServiceRequest, editServiceRequest } from '../../../services/apis/serviceRequest';

const CreateRequest = ({visible, onHandler}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const opportunity = useSelector(({opportunity}) => opportunity)
  // const companies = useSelector(({company}) => company.companies);
  // const loading = useSelector(({company}) => company.loading)
  // const states = useSelector(({country}) => country.states?.states)
  // const countries = useSelector(({country}) => country.all_countries)
  const {data, onLoad, onGetCountry, date, onChangeText, onValidate} = useCreateRequest();
  // const {uploadFile} = useUpload(); onChange,
  // const [loading, setLoading] = useState(false)
  // const [uploadType, setUploadType] = useState("")
  // const [uplodImage, setUploadImage] = useState("")
  const handleOk = () => {
    console.log('data')
    const validate = onValidate();
    if (!validate) {
      return;
    }
    const formData = {...data, expire_date: date}; //  image: uplodImage,
    console.log(formData)
    createServiceRequest({...data, ...formData}, dispatch, navigate)
    onHandler?.()
  };

  React.useEffect(() => {
    onLoad();
    onGetCountry();
  }, [onLoad, onGetCountry]);

  return (
    <Modal
        title="Add Service"
        open={visible}
        onOk={handleOk}
        // confirmLoading={loading}
        onCancel={onHandler}
      >
        <Form layout="vertical" className="w-100">
          <div className="row">
            <div style={{margin: '0'}}>
              <Form.Item label='Service Title'>
                <Input 
                  placeholder="Service Title" 
                  value={data.name} 
                  name="name"
                  onChange={onChangeText} 
                />
              </Form.Item>
            </div>
            <div style={{margin: '0'}}>
            <Form.Item label='Service Description'>
              <TextArea 
                placeholder="Service Description" 
                value={data.description}
                rows={4}
                name="description"
                onChange={onChangeText} 
              />
            </Form.Item>
            </div>
            <>
              {/* <div style={{margin: '0'}}>
                <Form.Item label='Service Address'>
                  <Input 
                    placeholder="Service Address" 
                    value={data.address}
                    name="address"
                    onChange={onChangeText} 
                  />
                </Form.Item>
              </div> */}
              {/* <div className='col-md-6' style={{margin: '0'}}>
                <Form.Item label='Service Link'>
                  <Input 
                    placeholder="Service Link" 
                    value={data.link}
                    name="link"
                    onChange={onChangeText} 
                  />
                </Form.Item>
              </div> */}
              {/* <div className='col-md-6' style={{margin: '0'}}>
                <Form.Item label='Service Amount'>
                  <Input 
                    placeholder="Service Amount" 
                    value={data.amount}
                    name="amount"
                    onChange={onChangeText} 
                  />
                </Form.Item>
              </div> */}
              {/* <div className='col-md-6' style={{margin: '0'}}>
                <Form.Item label='Service Duration'>
                  <Input 
                    placeholder="Service Duration" 
                    value={data.duration}
                    name="duration"
                    onChange={onChangeText} 
                  />
                </Form.Item>
              </div> */}
              {/* <div className='col-md-6' style={{margin: '0'}}>
                <Form.Item label='Select Expire Date'>
                  <DatePicker
                    onChange={onChange}
                    format="YYYY-MM-DD HH:mm:ss"
                    showTime={{
                      defaultValue: moment('00:00:00', 'HH:mm:ss'),
                    }}
                  />
                </Form.Item>
              </div> */}
              {/* <div style={{margin: '0'}}>              
                <Form.Item required label="Upload File">
                  <Input 
                    type="file" 
                    placeholder="file" 
                    onChange={({target: {files}})=> {const file = files[0]; uploadFile({file, setFile: setUploadType, setLoading, dispatch} )}} 
                  />
                </Form.Item>
              </div> */}
              {/* <div style={{margin: '0'}}>              
                <Form.Item required label="Upload Image">
                  <Input 
                    type="file" 
                    placeholder="Image" 
                    onChange={({target: {files}})=> {const file = files[0]; uploadFile({file, setFile: setUploadImage, setLoading, dispatch} )}} 
                  />
                </Form.Item>
              </div> */}
            </>
          </div>
        </Form>
      </Modal>
  )
}

export default CreateRequest

export const EditModal = ({name, edit, setEdit, onCloseEdit, viewEdit}) => {
  // const {uploadFile} = useUpload();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const [loading, setLoading] = useState(false)
  // const [uploadType, setUploadType] = useState("")
  // const [uplodImage, setUploadImage] = useState("")
  // const [date, setDate] = useState("")
  // const onChange = (date, dateString) => {
  //   setDate(dateString)
  // }
  const onFinish = (data) => {
    const formData = {
      ...data, 
      // image: uplodImage !== "" ? uplodImage : data.opportunity_image, 
      // opportunity_file: uploadType !== "" ? uploadType : data.opportunity_file, 
      // expire_date: date !== "" ? date : data.expire_date
    };
    editServiceRequest({...data, ...formData}, dispatch, navigate)
    console.log(formData)
  }
  return (
    <div>
      <Modal      
        title={`Edit ${name}`}
        visible={viewEdit} 
        onCancel={onCloseEdit} 
        footer={false}
      >
        <div>
          <Form layout="vertical" className="w-100" onFinish={()=>onFinish(edit)}>
            <div className="row">
              <div style={{margin: '0'}}>
                <Form.Item label='Edit Service Title'>
                  <Input 
                    required 
                    placeholder="Enter Service Title" 
                    value={edit.name} onChange={({target:{value}})=>setEdit({...edit, name: value})} 
                  />
                </Form.Item>
              </div>
              <div style={{margin: '0'}}>
              <Form.Item label='Edit Service Description'>
                <TextArea 
                  required 
                  rows={4}
                  placeholder="Enter Service Description" 
                  value={edit.body} 
                  onChange={({target:{value}})=>setEdit({...edit, body: value})} 
                />
              </Form.Item>
              </div>
              <>
                {/* <div style={{margin: '0'}}>
                <Form.Item label='Edit Service Address'>
                  <Input 
                    required 
                    placeholder="Enter Service Address" 
                    value={edit.address} 
                    onChange={({target:{value}})=>setEdit({...edit, address: value})} 
                  />
                </Form.Item>
                </div> */}
                {/* <div className='col-md-6' style={{margin: '0'}}>
                <Form.Item label='Edit Service Amount'>
                  <Input 
                    required 
                    placeholder="Enter Service Amount" 
                    value={edit.amount} 
                    onChange={({target:{value}})=>setEdit({...edit, amount: value})} 
                  />
                </Form.Item>
                </div> */}
                {/* <div className='col-md-6' style={{margin: '0'}}>
                <Form.Item label='Edit Service Link'>
                  <Input 
                    required 
                    placeholder="Enter Service Link" 
                    value={edit.link} 
                    onChange={({target:{value}})=>setEdit({...edit, link: value})} 
                  />
                </Form.Item>
                </div> */}
                {/* <div className='col-md-6' style={{margin: '0'}}>
                <Form.Item label='Edit Service Duration'>
                  <Input 
                    required 
                    placeholder="Enter Service Duration" 
                    value={edit.duration} 
                    onChange={({target:{value}})=>setEdit({...edit, duration: value})} 
                  />
                </Form.Item>
                </div> */}
                {/* <div className='col-md-6' style={{margin: '0'}}>
                  <Form.Item label='Select Service Date'>
                    <DatePicker
                      // value={edit.expire_date} 
                      onChange={onChange}
                      format="YYYY-MM-DD HH:mm:ss"
                      defaultValue={moment(edit.date)}
                      showTime={{
                        defaultValue: moment(edit.date),
                      }}
                    />
                  </Form.Item>
                </div> */}
                {/* <div style={{margin: '0'}}>              
                  <Form.Item required label="Upload File">
                    <Input 
                      type="file" 
                      placeholder="file" 
                      // value={edit.opportunity_file} 
                      onChange={({target: {files}})=> {const file = files[0]; uploadFile({file, setFile: setUploadType, setLoading, dispatch} )}} 
                    />
                  </Form.Item>
                </div> */}
                {/* <div style={{margin: '0'}}>              
                  <Form.Item required label="Upload Image">
                    <Input 
                      type="file" 
                      placeholder="Image" 
                      onChange={({target: {files}})=> {const file = files[0]; uploadFile({file, setFile: setUploadImage, setLoading, dispatch} )}} 
                    />
                  </Form.Item>
                </div> */}
              </>
              <div style={{margin: '0'}}>              
                <Form.Item>
                  <Button
                    htmlType="submit"
                    // loading={loading}
                    size="large"
                  >
                    Update
                  </Button>
                </Form.Item>
              </div>
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  )
}

export const ViewModal = ({name, onCloseView, viewOp, viewOne}) => {
  return (
    <div>
      <Modal      
        title={`View ${name}`}
        visible={viewOne} 
        onCancel={onCloseView} 
        footer={false}
      >
        <div className='row'>
          <div>
            <Image
              width={200}
              src={viewOp?.image}
            />
            <h4>Title: {viewOp?.name}</h4>
            <p>Description: {viewOp?.body}</p>
            <p>Type: {viewOp?.address}</p>
            <p><Tag color="success">Active</Tag></p>  
          </div>
        </div>
      </Modal>
    </div>
  )
}