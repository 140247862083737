import axios from "axios";
import baseURL from "../config/baseURL";

let headers = {};

const fetchApi = axios.create({
  baseURL,
  headers,
});

fetchApi.interceptors.request.use(
  async (config) => {
    const serial = localStorage.getItem("@serial");
    if (serial) {
      config.headers.Authorization = `bearer ${serial}`;
    }
    config.headers.Accept = "application/json";
    config.headers["Content-Type"] = "application/json";

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

fetchApi.interceptors.response.use(
  (response) =>
    new Promise((resolve, reject) => {
      resolve(response);
    }),
  (error) => {
    if (error.response?.status === 401 || error.response?.status === 403) {
      localStorage.removeItem("@serial");
      window.location.href = "/login";
    } else {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
  }
);

export default fetchApi;
