import React from 'react'

// components
import { Layout } from 'antd';
import { Menu } from 'antd';
import { MailOutlined, SettingOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';

// constants components
const { Header } = Layout;

const Navbar = () => {
  return (
    <Header className="site-layout-background"
      style={{ padding: 0}}>
    <Menu mode="horizontal" style={{display: 'flex', justifyContent: 'flex-end'}}>
        <Menu.Item key="mail" icon={<MailOutlined />}>
          Notification
        </Menu.Item>
        <Menu.SubMenu key="SubMenu" title="" icon={<SettingOutlined />}>
          <Menu.ItemGroup title="">
              <Menu.Item key="four" icon={<UserOutlined />}>
                Profile
              </Menu.Item>
              <Menu.Item key="five" icon={<LogoutOutlined />}>
                Logout
              </Menu.Item>
          </Menu.ItemGroup>
        </Menu.SubMenu>
    </Menu>
  </Header>
  )
}

export default Navbar
