import axiosInstance from "../../utility/axiosInstance";

// state
import { openNotification } from "../../modules";
import { setLoading, disableLoading } from "../../store/slices/loading";
import { disableCompanyLoading, setCompanyLoading, setCompany, setSearch, setCompanies, setCompanyDoc, setCompanyEmp } from "../../store/slices/company";

export const getAllCompany = async (dispatch, navigate) => {
    dispatch(setLoading());
    try {
        const response = await axiosInstance(navigate).get('/get-all-company');
        if (response.data) {
            dispatch(setCompany(response.data))
        }
    } catch (err) {
        const {data} = err.response;
        openNotification({type: 'error', title: 'Incorrect Credentials', description: data.message})
    }
    dispatch(disableLoading());
}

export const createCompany = async (data, dispatch, navigate) => {
    dispatch(setCompanyLoading());
    try {
        const response = await axiosInstance(navigate).post('/create-company', data);
        if (response.data) {
            openNotification({type: 'success', title: 'Successful!', description: response.data.message})
        }      
        setTimeout(() => {
            window.location.reload()
        }, 500)
    } catch (err) {
        const {data} = err.response;
        openNotification({type: 'error', title: 'Incorrect Credentials', description: data.message})
    }
    dispatch(disableCompanyLoading());
}

export const ActDecactCompany = async (id, dispatch, navigate) => {
    dispatch(setCompanyLoading());
    try {
        const response = await axiosInstance(navigate).get(`/toggle-company-status/${id}`, id);
        if (response.data) {
            openNotification({type: 'success', title: 'Successful!', description: response.data.message})
        }      
        setTimeout(() => {
            window.location.reload()
        }, 500)
    } catch (err) {
        const {data} = err.response;
        openNotification({type: 'error', title: 'Incorrect Credentials', description: data.message})
    }
    dispatch(disableCompanyLoading());
}

export const getPaginatedCompany = async (dispatch, navigate, page = 1) => {
    dispatch(setLoading());
    try {
        const response = await axiosInstance(navigate).get(`/get-company?page=${page}`);
        if (response.data) {
            dispatch(setCompanies(response.data))
        }
    } catch (err) {
        const {data} = err.response;
        openNotification({type: 'error', title: 'Incorrect Credentials', description: data.message})
    }
    dispatch(disableLoading());
}
// /admin/toggle-company-status/6dd0d42a-803c-4745-b1c8-26947994445
export const getCompanyEmp = async (id, dispatch, navigate) => {
    dispatch(setLoading());
    try {
        const response = await axiosInstance(navigate).get(`/company-employees/${id}`);
        if (response.data) {
            dispatch(setCompanyEmp(response.data))
        }
    } catch (err) {
        const {data} = err.response;
        openNotification({type: 'error', title: 'Incorrect Credentials', description: data.message})
    }
    dispatch(disableLoading());
}
export const getCompanyDoc = async (id, dispatch, navigate) => {
    dispatch(setLoading());
    try {
        const response = await axiosInstance(navigate).get(`/company-docs/${id}`);
        if (response.data) {
            dispatch(setCompanyDoc(response.data))
        }
    } catch (err) {
        const {data} = err.response;
        openNotification({type: 'error', title: 'Incorrect Credentials', description: data.message})
    }
    dispatch(disableLoading());
}
export const setRemoveUser = async (data, dispatch, navigate) => {
    const { id } = data
    dispatch(setCompanyLoading());
    try {
        const response = await axiosInstance(navigate).get(`/remove-from-company/${id}`);
        if (response.data) {
            openNotification({type: 'success', title: 'Successful!', description: response.data.message})
        }        
        setTimeout(() => {
            window.location.reload()
        }, 500)
    } catch (err) {
        const {data} = err.response;
        openNotification({type: 'error', title: 'Incorrect Credentials', description: data.message})
    }
    dispatch(disableLoading());
}

export const searchCompany = async (search, dispatch, navigate) => {
    dispatch(setLoading());
    try {
        const response = await axiosInstance(navigate).get(`/search-company/${search}`);
        if (response.data) {
            dispatch(setSearch(response.data))
        }
    } catch (err) {
        const {data} = err.response;
        openNotification({type: 'error', title: 'Incorrect Credentials', description: data.message})
    }
    dispatch(disableLoading());
}